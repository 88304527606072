import { createSelector } from 'reselect';
import { makeSelectUseCasesByIds } from 'src/selectors/useCases';
import { createShallowEqualSelector } from 'src/selectors/utils';
import _get from 'lodash/get';

export const getUseCasesByProfileFromStore = (state) => state.entities.accountUseCases.byProfileId;
export const getUseCasesByAdAccountFromStore = (state) => state.entities.accountUseCases.byAdAccountId;
export const getAsyncStatesByAction = (state) => state.entities.accountUseCases.asyncStatesByAction;

export const selectProfileUseCasesByProfileId = createSelector(
    [
        getUseCasesByProfileFromStore,
        (_, profileId) => profileId
    ],
    (profilesUseCases, profileId) => profilesUseCases[profileId] || []
);

export const selectAdAccountUseCaseIdsByAdAccountId = createSelector(
    [
        getUseCasesByAdAccountFromStore,
        (_, adAccountId) => adAccountId
    ],
    (adAccountsUseCases, adAccountId) => adAccountsUseCases[adAccountId] || []
);

export const selectIsAnyUseCaseEnabled = createSelector(
    [
        getUseCasesByProfileFromStore
    ],
    (profileUseCaseByProfileId) => Object.keys(profileUseCaseByProfileId).length > 0
);

export const makeSelectProfileUseCasesByProfileId = () => {
    const selectUseCasesByIds = makeSelectUseCasesByIds();
    return createShallowEqualSelector(
        [
            (state, profileId) => {
                const requiredUseCases = selectProfileUseCasesByProfileId(state, profileId);
                if (requiredUseCases.length > 0) {
                    return selectUseCasesByIds(state, requiredUseCases);
                }
                return [];
            }
        ],
        (useCases) => useCases
    );
};

export const makeSelectAdAccountUseCasesByAdAccountId = () => {
    const selectUseCasesByIds = makeSelectUseCasesByIds();
    return createShallowEqualSelector(
        [
            (state, adAccountId) => {
                const useCaseIds = selectAdAccountUseCaseIdsByAdAccountId(state, adAccountId);
                if (useCaseIds.length > 0) {
                    return selectUseCasesByIds(state, useCaseIds);
                }
                return [];
            }],
        (useCases) => useCases
    );
};

export const selectIsDeactivating = createSelector(
    [
        (state) => getAsyncStatesByAction(state).removeUseCase,
        (_, profileId) => profileId,
        (_, __, useCaseId) => useCaseId
    ],
    (asyncStates, profileId, useCaseId) => _get(asyncStates, [`${profileId}_${useCaseId}`, 'isPending'], false)
);
