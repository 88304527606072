import Highcharts from 'highcharts';
import highchartsFunnel from 'highcharts/modules/funnel';
import xrange from 'highcharts/modules/xrange';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsSolidGauge from 'highcharts/modules/solid-gauge';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import technicalIndicators from './technical-indicators';

highchartsMore(Highcharts);
highchartsFunnel(Highcharts);
highchartsSolidGauge(Highcharts);
noDataToDisplay(Highcharts);
technicalIndicators(Highcharts);
xrange(Highcharts);

Highcharts.dateFormats = {
    w(timestamp) {
        const date = new Date(timestamp);
        const day = date.getUTCDay() === 0 ? 7 : date.getUTCDay();
        date.setDate(date.getUTCDate() + 4 - day);
        const dayNumber = Math.floor((date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6)) / 86400000);
        return 1 + Math.floor(dayNumber / 7);
    },

    U(timestamp) {
        const date = new Date(timestamp);
        const onejan = new Date(date.getFullYear(), 0, 1);
        return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    }
};

const defaultColorPalette = [
    '#2f7ed8',
    '#434348',
    '#8bbc21',
    '#910000',
    '#1aadce',
    '#f28f43',
    '#613894',
    '#8f8fa4',
    '#e4d354',
    '#c45890',
    '#ac725e',
    '#c2cbec',
    '#d9b6cf',
    '#2b908f',
    '#91e8e1'
];

const sampleDataColorPalette = [
    '#f6a800',
    '#029dc0',
    '#80cedf'
];

export const getColorPalette = (isSampleMetric = false) => {
    if (isSampleMetric) {
        return sampleDataColorPalette;
    }
    return defaultColorPalette;
};

const marker = {
    symbol: 'circle',
    states: {
        hover: {
            enabled: true
        }
    }
};

const grey50 = '#a3a3a3';

const defaultTheme = {
    chart: {
        spacingTop: 10,
        spacingBottom: 0,
        zoomType: 'x',
        alignTicks: false,
        animation: false,
        backgroundColor: null,
        style: {
            fontFamily: '\'Inter\', \'Amiri\', sans-serif',
            fontSize: '13px',
            fontWeight: '400',
            position: 'absolute'
        }
    },
    tooltip: {
        useHTML: true,
        shape: 'callout',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 4,
        borderColor: null,
        style: {
            fontSize: '13px',
            fontWeight: '400',
            lineHeight: '18px'
        },
        positioner: function positioner(boxWidth, boxHeight, point) {
            // Set up the variables
            const { chart, distance } = this;
            const {
                plotLeft, plotTop, plotWidth, plotHeight
            } = chart;

            const pointX = point.plotX;
            const pointY = point.plotY;

            let x = pointX + plotLeft + (chart.inverted ? distance : -boxWidth - distance);
            let y = (pointY - boxHeight) + (plotTop + 15); // 15 means the point is 15 pixels up from the bottom of the tooltip
            let alignedRight = false;

            // It is too far to the left, adjust it
            if (x < 7) {
                x = plotLeft + Math.max(pointX, 0) + distance;
            }

            // Test to see if the tooltip is too far to the right,
            // if it is, move it back to be inside and then up to not cover the point.
            if ((x + boxWidth) > (plotLeft + plotWidth)) {
                x -= (x + boxWidth) - (plotLeft + plotWidth);
                y = (pointY - boxHeight) + (plotTop - distance);
                alignedRight = true;
            }

            // If it is now above the plot area, align it to the top of the plot area
            if (y < plotTop + 5) {
                y = plotTop + 5;

                // If the tooltip is still covering the point, move it below instead
                if (alignedRight && pointY >= y && pointY <= (y + boxHeight)) {
                    y = pointY + plotTop + distance; // below
                }
            }

            // Now if the tooltip is below the chart, move it up. It's better to cover the
            // point than to disappear outside the chart. #834.
            if (y + boxHeight > plotTop + plotHeight) {
                y = Math.max(plotTop, (plotTop + plotHeight) - boxHeight - distance); // below
            }

            return { x, y };
        }
    },
    colors: defaultColorPalette,
    legend: {
        useHTML: true,
        enabled: true,
        itemMarginBottom: 5,
        itemStyle: {
            fontSize: '13px',
            fontWeight: '400'
        },
        itemHoverStyle: {
            fontWeight: '500'
        },
        itemHiddenStyle: {
            color: grey50
        },
        symbolRadius: 0,
        labelFormatter: function labelFormatter() {
            return `<span dir="auto">${this.name}</span>`;
        }
    },
    lang: {
        thousandsSep: ','
    },
    title: {
        text: null
    },
    subtitle: {
        text: null
    },

    plotOptions: {
        series: {
            stickyTracking: false,
            marker,
            states: {
                hover: {
                    halo: false
                }
            }
        },
        line: {
            animation: false,
            shadow: false
        },
        column: {
            borderWidth: 0,
            animation: false,
            enableMouseTracking: true,
            shadow: false
        },
        bar: {
            borderWidth: 0,
            animation: false,
            enableMouseTracking: true,
            shadow: false
        },
        area: {
            animation: false,
            shadow: false
        },
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
            showInLegend: true,
            size: '90%',
            animation: false,
            shadow: false,
            enableMouseTracking: true
        },
        bubble: {
            minSize: 1,
            animation: false
        },
        funnel: {
            showInLegend: true
        },
        solidgauge: {
            animation: false
        },
        scatter: {
            animation: false
        },
        waterfall: {
            animation: false
        }
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    }
};

Highcharts.setOptions(defaultTheme);

export default Highcharts;
