import _includes from 'lodash/includes';
import { createSelector } from 'reselect';
import { getVisualizationsFromStore } from 'src/selectors/visualizations';
import TurndownService from 'turndown';
import {
    createShallowEqualSelector,
    makePropertyExtractor,
    makeSearchQueryFilter,
    makeStringSorter,
    defaultLoadingState
} from 'src/selectors/utils';
import _get from 'lodash/get';

export const getMetricsFromStore = (state) => state.entities.metrics.byId;
export const getMetricsIdsFromStore = (state) => state.entities.metrics.allIds;
export const getCustomMetricsIdsFromStore = (state) => state.entities.metrics.customMetricIds;
export const getMetricsAsyncState = (state) => state.entities.metrics.asyncStates;

const getMetricByIdFromStore = (state, id) => {
    const metric = getMetricsFromStore(state);
    return metric[id] || false;
};

export const selectAllMetrics = createSelector(
    [
        getMetricsIdsFromStore,
        getMetricsFromStore
    ],
    (metricIds, metricsById) => metricIds.map((id) => metricsById[id])
);

export const makeSelectMetricById = () => createShallowEqualSelector(
    [
        (state, id) => {
            const metric = getMetricByIdFromStore(state, id);
            if (metric) {
                const visualization = getVisualizationsFromStore(state)[metric.visualizationId];
                return {
                    metric, visualization
                };
            }
            return { metric: false };
        }
    ],
    ({ metric, visualization }) => {
        if (metric) {
            return Object.assign({}, metric, { visualization });
        }
        return false;
    }
);

export const makeSelectCustomMetricById = () => {
    const selectMetricById = makeSelectMetricById();
    return createSelector(
        [
            selectMetricById
        ],
        (metric) => {
            if (metric && metric.isCustomMetric) {
                return metric;
            }
            return false;
        }
    );
};

export const selectCustomMetrics = createSelector(
    [
        getCustomMetricsIdsFromStore,
        getMetricsFromStore
    ],
    (metricIds, metricsById) => metricIds.map((id) => metricsById[id])
);

export const selectCustomMetricsCount = createSelector(
    [
        getCustomMetricsIdsFromStore
    ],
    (customMetricIds) => customMetricIds.length
);

const sortingOrder = {
    name: 'asc',
    lastUpdateTime: 'desc',
    createTime: 'desc'
};

const makeOrderBySorter = () => createSelector(
    [
        (items) => items,
        (_, orderBy) => orderBy
    ],
    (items, orderBy = 'name') => {
        const orderDir = _get(sortingOrder, orderBy);
        if (orderDir) {
            return items.slice().sort(makeStringSorter(orderBy, orderDir));
        }
        return items;
    }
);

export const makeSelectCustomMetricIdsWithListFiltersApplied = () => {
    const searchQueryFilter = makeSearchQueryFilter('name');
    const sorter = makeOrderBySorter();
    const idsExtractor = makePropertyExtractor('id');
    return (state, filterBy, sortBy) => {
        const customMetrics = selectCustomMetrics(state);
        const queryFiltered = searchQueryFilter(customMetrics, filterBy);
        const sorted = sorter(queryFiltered, sortBy);
        return idsExtractor(sorted);
    };
};

const buildQQLConfigString = (metric) => {
    const qqlConfig = { config: metric.config };
    if (metric.parameters) {
        qqlConfig.parameters = metric.parameters;
    }
    return JSON.stringify(qqlConfig, undefined, 4);
};

export const selectInitialValuesForMetricBuilder = createSelector(
    [
        (metric, useCaseTags) => {
            const tags = metric ? useCaseTags.filter((tag) => _includes(metric.tagIds, tag.id)) : [];
            return {
                metric, tags
            };
        }
    ],
    ({ metric, tags }) => {
        if (metric) {
            const turndownService = new TurndownService({ linkStyle: 'referenced' });
            return {
                metricId: metric.id,
                metricName: metric.isCustomMetric ? metric.name : `Copy of ${metric.name}`,
                description: turndownService.turndown(metric.description),
                useCases: turndownService.turndown(metric.useCases),
                visualizationType: metric.visualizationId,
                qqlConsole: metric.config.qqlQuery,
                qqlConfig: buildQQLConfigString(metric),
                metricSummary: metric.summary,
                isCustomMetric: metric.isCustomMetric,
                isMetric: true,
                platformType: metric.platformType,
                tags
            };
        }
        return {
            metricId: null,
            metricName: 'My new Custom Metric',
            description: '',
            useCases: '',
            visualizationType: '1',
            qqlConsole: '',
            qqlConfig: JSON.stringify({ config: { qqlQuery: '' } }, undefined, 4),
            metricSummary: '',
            isCustomMetric: true,
            isMetric: false,
            platformType: '',
            tags
        };
    }
);

export const makeSelectGetMetricLoadingState = () => createSelector(
    [
        getMetricsAsyncState,
        (_, metricId) => metricId
    ],
    (asyncStates, metricId) => _get(asyncStates, ['get', metricId], defaultLoadingState)
);
