import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _split from 'lodash/split';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import _memoize from 'lodash/memoize';
import { defaultNameStringOrderWithExcludedPrefix } from 'src/selectors/accounts';
import { getFieldName } from 'src/utils/authTransactionUtils';

export const MAX_NUMBER_OF_COLUMNS = 3;

const getStateFromConnections = (valids, invalids) => {
    if (invalids.length > 0 && valids.length === 0) {
        return 'error';
    }
    if (invalids.length > 0 && valids.length > 0) {
        return 'warning';
    }
    return 'ok';
};

export const getUseCaseStatusByAccountUseCasesAuthUser = (requiredUseCases, accountUseCasesAuthUsers) => {
    const useCasesStatus = {};
    requiredUseCases.forEach((useCase) => {
        const { id, name } = useCase;
        useCasesStatus[id] = [];
        accountUseCasesAuthUsers.forEach((accountUseCasesAuthUser) => {
            if (accountUseCasesAuthUser.useCaseId === id) {
                useCasesStatus[id].push(Object.assign({}, accountUseCasesAuthUser, { name, authUser: accountUseCasesAuthUser.userId }));
            }
        });

        if (_isEmpty(useCasesStatus[id])) {
            useCasesStatus[id].push({
                useCaseId: id, name, valid: false, invalidReason: null, authUser: null
            });
        }
    });
    return useCasesStatus;
};

export const getUseCaseStatusByAuthUserAuthAccountUseCases = (requiredUseCases, authUserAuthAccountUseCases) => {
    const useCasesStatus = {};
    requiredUseCases.forEach((useCase) => {
        const { id, name } = useCase;
        useCasesStatus[id] = [];
        authUserAuthAccountUseCases.forEach((authUserAuthAccountUseCase) => {
            if (authUserAuthAccountUseCase.useCaseId === id) {
                useCasesStatus[id].push(Object.assign({}, authUserAuthAccountUseCase, {
                    name, authUser: authUserAuthAccountUseCase.userId, valid: true, invalidReason: null
                }));
            }
        });

        if (_isEmpty(useCasesStatus[id])) {
            useCasesStatus[id].push({
                useCaseId: id, name, valid: false, invalidReason: null, authUser: null
            });
        }
    });
    return useCasesStatus;
};

const getValidAndInValidUseCasesStatus = (useCasesStatus) => {
    const validUseCases = [];
    const invalidUseCases = [];
    useCasesStatus.forEach((useCaseStatus) => {
        if (useCaseStatus.valid === true) {
            validUseCases.push(useCaseStatus);
        } else {
            invalidUseCases.push(useCaseStatus);
        }
    });
    return {
        validUseCases,
        invalidUseCases
    };
};

export const getFinalUseCaseStatuses = (useCasesStatus) => {
    const statuses = {};
    Object.keys(useCasesStatus).forEach((useCaseId) => {
        const { validUseCases, invalidUseCases } = getValidAndInValidUseCasesStatus(useCasesStatus[useCaseId]);
        if (validUseCases.length > 0) {
            statuses[useCaseId] = { name: validUseCases[0].name, useCaseStatus: true, reason: null };
        } else if (invalidUseCases.length > 0) {
            statuses[useCaseId] = { name: invalidUseCases[0].name, useCaseStatus: false, reason: '' };
        }
    });
    return statuses;
};

const getInvalidReasonsByUseCaseId = (useCasesStatus, authUserNames) => {
    const invalidReasonsByUseCaseId = {};
    Object.keys(useCasesStatus).forEach((useCaseId) => {
        const { validUseCases, invalidUseCases } = getValidAndInValidUseCasesStatus(useCasesStatus[useCaseId]);
        if (validUseCases.length === 0 && invalidUseCases.length > 0) {
            const userNames = [];
            invalidUseCases.forEach((invalidUseCase) => {
                const { authUser } = invalidUseCase;
                if (authUserNames[authUser]) {
                    userNames.push(authUserNames[authUser]);
                }
            });
            const invalidReason = userNames.length > 0 ? `${userNames.join(', ')} lost access to this use case.` : 'No accounts have fulfilled this use case.';
            invalidReasonsByUseCaseId[useCaseId] = invalidReason;
        }
    });

    return invalidReasonsByUseCaseId;
};

export const getFinalUseCasesStatuesFromAccountUseCasesAuthUsers = (requiredUseCases, accountUseCasesAuthUsers) => {
    const useCasesStatus = getUseCaseStatusByAccountUseCasesAuthUser(requiredUseCases, accountUseCasesAuthUsers);
    return getFinalUseCaseStatuses(useCasesStatus);
};

export const getFinalUseCasesStatuesFromAuthUserAuthAccountUseCases = (requiredUseCases, authUserAuthAccountUseCases) => {
    const useCasesStatus = getUseCaseStatusByAuthUserAuthAccountUseCases(requiredUseCases, authUserAuthAccountUseCases);
    return getFinalUseCaseStatuses(useCasesStatus);
};

const getInvalidReasonsFromAuthUseCasesByUseCaseId = (requiredUseCases, accountUseCasesAuthUsers, authUserNames) => {
    const useCasesStatus = getUseCaseStatusByAccountUseCasesAuthUser(requiredUseCases, accountUseCasesAuthUsers);
    return getInvalidReasonsByUseCaseId(useCasesStatus, authUserNames);
};

export const getLabelAndFinalState = (requiredUseCases, accountUseCasesAuthUsers) => {
    const statuses = getFinalUseCasesStatuesFromAccountUseCasesAuthUsers(requiredUseCases, accountUseCasesAuthUsers);
    // create an overview of use case status
    const validUseCases = [];
    const invalidUseCases = [];

    Object.keys(statuses).forEach((useCaseId) => {
        const useCase = statuses[useCaseId];
        if (useCase.useCaseStatus === true) {
            validUseCases.push(useCase);
        } else {
            invalidUseCases.push(useCase);
        }
    });

    const finalState = getStateFromConnections(validUseCases, invalidUseCases);
    let finalTooltip = null;
    if (validUseCases.length > 0) {
        finalTooltip = `Connected: ${validUseCases.map((useCase) => useCase.name).join(', ')}.`;
    }

    if (invalidUseCases.length > 0) {
        const disconnectedLabel = `Disconnected: ${invalidUseCases.map((useCase) => useCase.name).join(', ')}.`;
        finalTooltip = finalTooltip !== null ? `${finalTooltip} ${disconnectedLabel}` : `${disconnectedLabel}`;
    }

    return {
        finalState,
        finalTooltip
    };
};

const appendUseCase = (accountsUseCases, accountId, useCaseId) => {
    if (accountsUseCases[accountId]) {
        accountsUseCases[accountId].push(useCaseId);
    } else {
        Object.assign(accountsUseCases, { [accountId]: [useCaseId] });
    }
};

export const getAuthTransactionRequirementsByProfile = (formValues) => {
    const profilesUseCases = {};
    const adAccountsUseCases = {};
    Object.keys(formValues).forEach((formValue) => {
        const value = formValues[formValue];
        if (value) {
            const accountAndUseCaseArray = _split(formValue, '_');
            const accountType = accountAndUseCaseArray[0];
            const accountId = accountAndUseCaseArray[1];
            const useCaseId = accountAndUseCaseArray[2];
            if (accountType === 'profiles') {
                appendUseCase(profilesUseCases, accountId, useCaseId);
            }
            if (accountType === 'adAccounts') {
                appendUseCase(adAccountsUseCases, accountId, useCaseId);
            }
        }
    });
    return {
        profilesUseCases,
        adAccountsUseCases
    };
};

export const getInsightsLabelFrom = (platformType) => {
    const graphPlatforms = ['facebook', 'instagram', 'meta'];
    return _includes(graphPlatforms, platformType) ? 'Insights' : 'Analytics';
};

const getUseCaseStatus = (useCasesStatuses, id) => _get(useCasesStatuses, id, false);

const findUseCasesOrFalse = (useCases, id) => {
    for (let i = 0; i < useCases.length; i++) {
        if (useCases[i].id === id) {
            return useCases[i];
        }
    }
    return false;
};

export const getUseCaseFields = (platformTypeUseCases, useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases) => {
    const useCaseFormFields = [];
    const useCasesStatuses = getFinalUseCasesStatuesFromAccountUseCasesAuthUsers(useCases, accountUseCasesAuthUsers);
    const authUserAuthAccountUseCasesStatuses = getFinalUseCasesStatuesFromAuthUserAuthAccountUseCases(platformTypeUseCases, authUsersAuthAccountUseCases);
    platformTypeUseCases.forEach((currentUseCase) => {
        const { id, name } = currentUseCase;
        const useCase = getUseCaseStatus(useCasesStatuses, id);
        let hasWarning;
        if (useCase) {
            hasWarning = !useCase.useCaseStatus;
        } else {
            const potentialUseCase = getUseCaseStatus(authUserAuthAccountUseCasesStatuses, id);
            hasWarning = !potentialUseCase.useCaseStatus;
        }
        useCaseFormFields.push(Object.assign({}, currentUseCase, { name: `${id}_${name}`, label: name, hasWarning }));
    });
    return useCaseFormFields;
};

export const getUseCaseInitialFormValues = (account, platformTypeUseCases, hideViewAllAuthenticationButton) => {
    // create the form initialValue
    const initialFormValue = { accountId: account.id, showGoToAuthenticationsButton: !hideViewAllAuthenticationButton };
    platformTypeUseCases.forEach((useCases) => {
        const { id, name } = useCases;
        const useCase = findUseCasesOrFalse(account.useCases, id);
        Object.assign(initialFormValue, { [`${id}_${name}`]: !!useCase });
    });

    return initialFormValue;
};

export const getProfileIdsFromUseCases = _memoize((authUsersAuthProfileUseCases, profileUseCasesAuthUsers) => _uniq([...authUsersAuthProfileUseCases, ...profileUseCasesAuthUsers].map(({ profileId }) => profileId)));

export const getAdAccountIdsFromUseCases = _memoize((authUsersAuthAdAccountUseCases, adAccountUseCasesUseCasesAuthUsers) => _uniq([...authUsersAuthAdAccountUseCases, ...adAccountUseCasesUseCasesAuthUsers].map(({ adAccountId }) => adAccountId)));

export const arrangeAccountsByPlatformType = _memoize((accounts) => {
    const accountsByPlatformType = {};
    accounts.forEach((account) => {
        const { platformType } = account;
        if (accountsByPlatformType[platformType]) {
            accountsByPlatformType[platformType].push(account);
        } else {
            Object.assign(accountsByPlatformType, { [platformType]: [account] });
        }
    });
    return accountsByPlatformType;
});

export const getFormNameByAccountNameAndContext = (context, adAccountId) => `${context}_${adAccountId}_form`;

export const getAccountId = (fieldName) => _split(fieldName, '_')[1];

export const isProfile = (fieldName) => {
    const parts = _split(fieldName, '_');
    return parts[0] === 'profile';
};

export const isAdAccount = (fieldName) => {
    const parts = _split(fieldName, '_');
    return parts[0] === 'adAccount';
};

const profileListPrefix = 'profile_';
export const getProfileListItemName = (profileId) => `${profileListPrefix}${profileId}`;
export const getProfilesListItemNames = _memoize((profileIds) => profileIds.map((profileId) => getProfileListItemName(profileId)));

export const getAccountIdsFromListNames = _memoize((accountListNames) => {
    const profileIds = [];
    const adAccountIds = [];
    accountListNames.forEach((name) => {
        const accountId = getAccountId(name);
        if (isProfile(name)) {
            profileIds.push(accountId);
        }
        if (isAdAccount(name)) {
            adAccountIds.push(accountId);
        }
    });
    return {
        profileIds,
        adAccountIds
    };
});

const adAccountListPrefix = 'adAccount_';
export const getAdAccountListItemName = (adAccountId) => `${adAccountListPrefix}${adAccountId}`;
export const getAdAccountsListItemNames = _memoize((adAccountIds) => adAccountIds.map((adAccountId) => getAdAccountListItemName(adAccountId)));
export const getProfileAndAdAccountListNames = _memoize((profileIds, adAccountIds) => [...getProfilesListItemNames(profileIds), ...getAdAccountsListItemNames(adAccountIds)]);

const getUseCaseIdsByProfileIdFromBrokeProfileUseCases = (brokenProfilesUseCasesByPlatformType, accountIdName = 'profileId') => {
    const useCasesByProfileId = {};
    Object.values(brokenProfilesUseCasesByPlatformType).forEach((brokenProfilesUseCases) => {
        brokenProfilesUseCases.forEach((brokenProfileUseCases) => {
            const accountId = brokenProfileUseCases[accountIdName];
            const { invalidReasonsByUseCaseId } = brokenProfileUseCases;
            Object.assign(useCasesByProfileId, { [accountId]: Object.keys(invalidReasonsByUseCaseId) });
        });
    });
    return useCasesByProfileId;
};

const getAccountIdNameByAccountType = (accountType) => (accountType === 'profiles' ? 'profileId' : 'adAccountId');

export const getAllBrokenAccountIds = _memoize((brokenProfilesUseCasesByPlatformType, accountType) => {
    const accountIds = [];
    const accountIdName = getAccountIdNameByAccountType(accountType);
    Object.values(brokenProfilesUseCasesByPlatformType).forEach((brokenProfilesUseCases) => {
        brokenProfilesUseCases.forEach((brokenProfileUseCases) => {
            accountIds.push(brokenProfileUseCases[accountIdName]);
        });
    });
    return accountIds;
});

export const appendBrokenAccountUseCasesInInitialFormValues = (initialFormValues, brokenAccountUseCasesByPlatformType, checkedAccountId, accountType = 'profiles') => {
    const accountIdName = getAccountIdNameByAccountType(accountType);
    const useCasesByAccountId = getUseCaseIdsByProfileIdFromBrokeProfileUseCases(brokenAccountUseCasesByPlatformType, accountIdName);
    Object.keys(useCasesByAccountId).forEach((accountId) => {
        const useCaseIds = useCasesByAccountId[accountId];
        if (_includes(checkedAccountId, accountId)) {
            useCaseIds.forEach((useCaseId) => {
                Object.assign(initialFormValues, { [getFieldName(accountType, accountId, useCaseId)]: true });
            });
        }
    });
    return initialFormValues;
};

export const getBrokenAccountsUseCasesByPlatformType = _memoize((accounts, authUsers, accountType) => {
    const authUserNames = {};
    authUsers.forEach((authUser) => {
        authUserNames[authUser.id] = authUser.name;
    });
    const brokenAccountsUseCasesByPlatformType = {};
    const orderedAccounts = accounts.slice().sort(defaultNameStringOrderWithExcludedPrefix());
    const accountIdName = getAccountIdNameByAccountType(accountType);
    orderedAccounts.forEach((account) => {
        const {
            id, platformType, useCases, accountUseCasesAuthUsers
        } = account;
        const invalidReasonsByUseCaseId = getInvalidReasonsFromAuthUseCasesByUseCaseId(useCases, accountUseCasesAuthUsers, authUserNames);
        if (Object.keys(invalidReasonsByUseCaseId).length > 0) {
            if (brokenAccountsUseCasesByPlatformType[platformType]) {
                brokenAccountsUseCasesByPlatformType[platformType].push({ [accountIdName]: id, invalidReasonsByUseCaseId });
            } else {
                Object.assign(brokenAccountsUseCasesByPlatformType, { [platformType]: [{ [accountIdName]: id, invalidReasonsByUseCaseId }] });
            }
        }
    });
    return brokenAccountsUseCasesByPlatformType;
});
