import _escapeRegExp from 'lodash/escapeRegExp';
import _isString from 'lodash/isString';
import memoize from 'memoize-one';
import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

export const getIconForPlatformType = (platformType, isDeprecated = false) => {
    // if there is no platformType provided this value is null
    // platformType.toLowerCase() will raise an error then.
    // So we fallback to custom-metrics icon
    if (platformType) {
        switch (platformType) {
            case 'meta':
                return 'meta';

            case 'facebook':
                return 'facebook';

            case 'instagram':
                return 'instagram';

            case 'twitter':
                return 'x';
            case 'threads':
                return 'threads';
            case 'youtube':
                return 'youtube';
            case 'snapchatShow':
                return 'snapchat';
            case 'tiktok': {
                if (isDeprecated) {
                    return 'tiktokdevs';
                }
                return 'tiktok';
            }
            case 'overall':
                return 'overall';
            case 'adAccounts':
                return 'profiles';
            case 'general':
                return 'data';

            default:
                return platformType.toLowerCase();
        }
    }
    return 'custom-metrics';
};

export const getTruncatedTextWithEllipsis = (text, size) => {
    if (text.length > size) {
        const textTruncated = text.substring(0, size);
        return `${textTruncated}...`;
    }
    return text;
};

export const cleanupHtmlTagsFromText = (text) => text.replace(/(<([^>]+)>)/gi, '').trim();

const valueDirectives = {
    keyword: '\\q{word}'
};

export const transformKeyWordOnlyValue = (string) => {
    if (_startsWith(string, valueDirectives.keyword)) {
        return {
            value: _replace(string, valueDirectives.keyword, ''),
            wordOnly: true
        };
    }

    return {
        value: string,
        wordOnly: false
    };
};

export const parseKeyWordOnlyValue = (value, wordOnly) => {
    if (wordOnly) {
        return `${valueDirectives.keyword}${value}`;
    }
    return value;
};

export const getProcessedKeyWords = (value) => {
    const { wordOnly, value: processedValue } = transformKeyWordOnlyValue(value);
    if (wordOnly) {
        return `= ${processedValue}`;
    }
    return processedValue;
};

const getRegexInputForKeyWords = (keyword) => {
    const { value, wordOnly } = transformKeyWordOnlyValue(keyword);
    if (wordOnly) {
        return `(?:^|\\s)${_escapeRegExp(value)}\\b`;
    }
    return _escapeRegExp(value);
};

export const addHtmlTagsToKeywordsInText = (phrase, keywords, tag) => {
    const escapedKeywords = keywords.map((keyword) => getRegexInputForKeyWords(keyword));
    const pattern = new RegExp(`(${escapedKeywords.join('|')})`, 'gi');
    return phrase.replace(pattern, (match) => `<${tag}>${match}</${tag}>`);
};

export const concatenatePostContentItems = (contentItems) => {
    const contentItemStrings = contentItems.map((item) => item.content).filter((item) => item !== '');
    return contentItemStrings.join(' | ');
};

export const getPostSnippetBasedOnPostTextKeywords = (contentItems, queries = [], maxChars) => {
    // We concatenate content items if there are multiple
    const content = concatenatePostContentItems(contentItems);

    // If there is no search query, return original text considering max limit
    if (queries.length === 0) {
        return getTruncatedTextWithEllipsis(content, maxChars);
    }

    // If the first search query has no match, return original text considering max limit
    const { value: firstQuery } = transformKeyWordOnlyValue(queries[0]);
    const firstQueryLen = firstQuery.length;
    const queryRegex = new RegExp(getRegexInputForKeyWords(queries[0]), 'i');
    const pos = content.search(queryRegex);
    if (pos === -1) {
        return getTruncatedTextWithEllipsis(content, maxChars);
    }

    // Anchor the snippet around the match of the first search query
    const charPadding = parseInt((maxChars - firstQueryLen) / 2, 10);
    const leftPos = Math.max(0, pos - charPadding);
    const rightPos = Math.min(pos + firstQueryLen + charPadding, content.length) + 1;

    const prefix = (leftPos > 0) ? '...' : '';
    const suffix = (rightPos < content.length) ? '...' : '';

    return prefix + content.substring(leftPos + prefix.length, rightPos - suffix.length) + suffix;
};

export const makeCommaSeparatedString = (arr, conjunctionWord, wrappingChar = '') => {
    const listStart = arr.slice(0, -1).map((item) => `${wrappingChar}${item}${wrappingChar}`).join(', ');
    const listEnd = arr.slice(-1).map((item) => `${wrappingChar}${item}${wrappingChar}`);
    const conjunction = arr.length <= 1 ? '' : ` ${conjunctionWord} `;

    return [listStart, listEnd].join(conjunction);
};

export const makeArrayStringSorter = (direction) => (a, b) => {
    const alc = _isString(a) ? a.toLowerCase().trim() : '';
    const blc = _isString(b) ? b.toLowerCase().trim() : '';

    if (alc > blc) {
        return direction === 'desc' ? -1 : 1;
    }

    if (alc < blc) {
        return direction === 'desc' ? 1 : -1;
    }
    return 0;
};

export const hasDashboardCustomLogo = (spaceCustomLogo, dashboardCustomLogo) => {
    if (spaceCustomLogo !== null && spaceCustomLogo !== '') {
        const space = spaceCustomLogo.split('?');
        const dashboard = dashboardCustomLogo.split('?');
        return space[0] !== dashboard[0];
    }
    return true;
};

// this works on CamelCase or underscore_case
export const injectWordBreakOpportunityTags = (text) => text.replace(/([A-Z]|_)/g, '<wbr />$1');

export const isValidHttpUrl = (url) => /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(url);

export const getCreatableOptionOrNull = memoize((searchQuery, options, validator = undefined) => {
    const trimmedSearchQuery = searchQuery.trim();
    if (trimmedSearchQuery !== '') {
        if (!options.some((option) => (option.label.toLowerCase() === trimmedSearchQuery.toLowerCase()))) {
            const result = {
                optionToCreate: trimmedSearchQuery,
                validationError: undefined
            };
            if (validator !== undefined) {
                if (typeof validator === 'function') {
                    result.validationError = validator(trimmedSearchQuery);
                }
                if (typeof validator === 'object') {
                    validator.forEach((currentValidator) => {
                        result.validationError = currentValidator(trimmedSearchQuery);
                    });
                }
            }
            return result;
        }
        return null;
    }
    return null;
});
