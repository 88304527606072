export const EXPORT_FILE_REQUEST = 'EXPORT_FILE_REQUEST';
export const EXPORT_FILE_SUCCESS = 'EXPORT_FILE_SUCCESS';
export const EXPORT_FILE_ERROR = 'EXPORT_FILE_ERROR';
export const EXPORT_FILE_DELETE = 'EXPORT_FILE_DELETE';

export const TRIGGER_DASHBOARD_EXPORT = 'TRIGGER_DASHBOARD_EXPORT';
export const TRIGGER_DASHBOARD_METRIC_EXPORT = 'TRIGGER_DASHBOARD_METRIC_EXPORT';
export const TRIGGER_METRIC_EXPORT = 'TRIGGER_METRIC_EXPORT';
export const TRIGGER_REPORT_PREVIEW = 'TRIGGER_REPORT_PREVIEW';
export const TRIGGER_INVOICE_DOWNLOAD = 'TRIGGER_INVOICE_DOWNLOAD';

export const TRIGGER_DATA_PUSH_TASK_LOG_DOWNLOAD = 'TRIGGER_DATA_PUSH_TASK_LOG_DOWNLOAD';

export const SET_REPORT_PREVIEW_PRENDING = 'SET_REPORT_PREVIEW_PRENDING';
export const UNSET_REPORT_PREVIEW_PRENDING = 'UNSET_REPORT_PREVIEW_PRENDING';

export const exportFileRequest = (fileExportIdentifier, debugInfo) => ({
    type: EXPORT_FILE_REQUEST,
    payload: { fileExportIdentifier, debugInfo }
});

export const exportFileSuccess = (fileExportIdentifier, blob, fileType, realFileName) => ({
    type: EXPORT_FILE_SUCCESS,
    payload: {
        fileExportIdentifier, blob, fileType, realFileName
    }
});

export const exportFileError = (fileExportIdentifier, error) => ({
    type: EXPORT_FILE_ERROR,
    payload: { fileExportIdentifier, error }
});

export const triggerDashboardExport = (batchServerRequest, fileName) => ({
    type: TRIGGER_DASHBOARD_EXPORT,
    payload: {
        batchServerRequest,
        fileName
    }
});

export const triggerDashboardMetricExport = (serverRequest, fileName) => ({
    type: TRIGGER_DASHBOARD_METRIC_EXPORT,
    payload: {
        serverRequest,
        fileName
    }
});

export const triggerMetricExport = (serverRequest, fileName) => ({
    type: TRIGGER_METRIC_EXPORT,
    payload: {
        serverRequest,
        fileName
    }
});

export const triggerReportPreview = (dashboardId, formName) => ({
    type: TRIGGER_REPORT_PREVIEW,
    payload: { dashboardId, formName }
});

export const triggerInvoiceDownload = (id, invoiceNumber) => ({
    type: TRIGGER_INVOICE_DOWNLOAD,
    payload: {
        id, invoiceNumber
    }
});

export const triggerDataPushTaskLogDownload = (id, dataPushTaskId, rawDataType) => ({
    type: TRIGGER_DATA_PUSH_TASK_LOG_DOWNLOAD,
    payload: {
        id, dataPushTaskId, rawDataType
    }
});

export const exportFileDelete = (fileExportIdentifier) => ({
    type: EXPORT_FILE_DELETE,
    payload: { fileExportIdentifier }
});

export const setReportPreviewPending = () => ({
    type: SET_REPORT_PREVIEW_PRENDING
});

export const unsetReportPreviewPending = () => ({
    type: UNSET_REPORT_PREVIEW_PRENDING
});
