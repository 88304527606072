import Highcharts from 'src/components/chartViews/highcharts/Highcharts';
import { number } from 'src/components/Number';
import { numberString } from 'src/components/NumberString';
import { makeFormatNumber } from 'src/components/chartViews/highcharts/configs/utils';
import _get from 'lodash/get';

const formatNumber = makeFormatNumber(Highcharts);

export const decimal = (value, numberFormat) => {
    const parsedValue = parseFloat(value);

    if (value === null || window.Number.isNaN(value)) {
        return 'n/a';
    }
    if (typeof value === 'string') {
        // if a string was provided, this is because in QQL someone was using e.g. PRINTF to specify the number of decimals in the query
        // in that case we simply take the exact string we got, just localize it
        return numberString(value, numberFormat);
    }
    // in all other cases we definitely move on with the parsed value and just set a fixed number of decimals
    // TODO: there is no way to provide the number of decimals yet as a column config but this would be really useful
    return number(parsedValue, numberFormat, 2);
};

export const integer = (value, numberFormat) => {
    const parsedValue = parseInt(value, 10);
    if (parsedValue !== null && !window.Number.isNaN(parsedValue)) {
        return number(parsedValue, numberFormat);
    }
    return 'n/a';
};

const numberWithSuffix = (value, numberFormat, suffix) => {
    const parsedValue = parseFloat(value);

    let valueToShow;

    if (value === null || window.Number.isNaN(value)) {
        valueToShow = 'n/a';
    } else if (typeof value === 'string') {
        // if a string was provided, this is because in QQL someone was using e.g. PRINTF to specify the number of decimals in the query
        // in that case we simply take the exact string we got, just localize it
        valueToShow = numberString(value, numberFormat);
    } else {
        // in all other cases we definitely move on with the parsed value and just set a fixed number of decimals
        // TODO: there is no way to provide the number of decimals yet as a column config but this would be really useful
        valueToShow = number(parsedValue, numberFormat, 2);
    }
    return `${valueToShow}${value !== null ? suffix : ''}`;
};

export const percent = (value, numberFormat) => numberWithSuffix(value, numberFormat, '%');

export const money = (value, currency, numberFormat) => numberWithSuffix(value, numberFormat, currency);

export const shortenInteger = (value, numberFormat) => formatNumber(numberFormat, value, 0, true, 2);

const renderers = {
    integer, decimal, percent, shortenInteger
};

export const getRendererByType = (type) => _get(renderers, type, integer);
