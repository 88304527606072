import _get from 'lodash/get';
import _includes from 'lodash/includes';
import moment from 'moment-timezone';
import { makeArrayStringSorter } from 'src/utils/string';
import _range from 'lodash/range';

const unKnownKpi = {
    title: 'Unknown kpi',
    icon: 'warning'
};

const metricTitleAndIcon = {
    reactions: {
        tooltip: 'Reactions include likes, love, wow, haha, sad and angry.',
        icon: 'likes'
    },
    likes: {
        tooltip: 'Likes',
        icon: 'fave'
    },
    comments: {
        tooltip: 'Comments',
        icon: 'comment'
    },
    shares: {
        tooltip: 'Shares',
        icon: 'shares'
    },
    retweets: {
        tooltip: 'Retweets',
        icon: 'retweets'
    },
    views: {
        tooltip: 'Views',
        icon: 'views'
    },
    reach: {
        tooltip: 'Viewers',
        icon: 'viewers'
    }
};

export const getMetricTitleAndIcon = (postMetric) => _get(metricTitleAndIcon, postMetric, unKnownKpi);

export const getPostLinkLabel = (postTypeName) => `View ${postTypeName}`;

export const getNumberOfPages = (total, limit) => {
    if (total === 0) return 1;
    return Math.ceil(total / limit);
};

export const getPageNumber = (limit, start) => {
    if (start === 0) {
        return 1;
    }
    const pageNumber = (start / limit) + 1;
    if (pageNumber < 1) {
        return 1;
    }
    return pageNumber;
};

export const getPostIdsByCreateTimes = (posts) => {
    const postsByCreateTimes = {};
    posts.forEach((post) => {
        const { id, time, timezone } = post;
        const date = moment.tz(time, timezone).format('YYYY-MM-DD');
        if (postsByCreateTimes[date]) {
            postsByCreateTimes[date].postIds.push(id);
        } else {
            Object.assign(postsByCreateTimes, { [date]: { postIds: [id], timezone } });
        }
    });
    return postsByCreateTimes;
};

export const getProfileNamesFromUseCaseViolationWarnings = (useCaseViolation) => {
    const profileNames = [];
    useCaseViolation.forEach(({ profiles }) => {
        profiles.forEach(({ name }) => {
            if (!_includes(profileNames, name)) {
                profileNames.push(name);
            }
        });
    });
    return profileNames.sort(makeArrayStringSorter('asc'));
};

export const getPostTaggingListName = (pageNumber) => `post_tagging_${pageNumber}`;

export const getListNamesByNumberOfPages = (totalResult, limit) => {
    const numberOfPages = getNumberOfPages(totalResult, limit);
    return _range(1, numberOfPages).map((pageNumber) => getPostTaggingListName(pageNumber));
};
