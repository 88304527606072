import { createSlice, createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import { PREPARE_EXPORT_DATA } from 'src/actions/export';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { makeSearchQueryFilter } from 'src/selectors/accounts';
import { makePropertyExtractor, makeStringSorter } from 'src/selectors/utils';

const initialState = {};

const name = 'faceliftTags';

const init = (state, action) => {
    const { faceliftTags } = action.payload;
    return faceliftTags;
};

const faceliftTags = createSlice({
    name,
    initialState,
    extraReducers: {
        [USER_LOGGED_IN]: init,
        [PREPARE_EXPORT_DATA]: init,
        [SHARED_DASHBOARD_BOOTSTRAP_SUCCESS]: init,
    },
});

export const selectAllTagIds = createSelector(
    (state) => { const values = state.entities[name]; return values; },
    (values) => Object.keys(values)
);

export const selectAllTags = createSelector(
    (state) => { const values = state.entities[name]; return values; },
    (values) => Object.keys(values).map((key) => values[key])

);

export const selectFaceliftTagById = (state, id) => {
    const values = state.entities[name];
    return _get(values, id);
};

export const makeSelectSortedFaceliftTagIds = () => {
    const idsExtractor = makePropertyExtractor();
    const searchQueryFilter = makeSearchQueryFilter((items, filterQuery) => items.filter((item) => (
        item.name.toLowerCase().indexOf(filterQuery) > -1
    )));

    return createSelector(
        [
            selectAllTags,
            (_, filterQuery) => filterQuery
        ],
        (activePostTags, filterQuery) => {
            const sorted = searchQueryFilter(activePostTags, filterQuery).sort(makeStringSorter('name', 'asc'));
            return idsExtractor(sorted);
        }
    );
};

export default faceliftTags.reducer;
