import { makeStringSorter, orderPlatformTypesByUsage } from 'src/selectors/utils';
import {
    getDashboardMetricsFromStore,
    getDashboardMetricsLayoutsFromStore,
    makeSelectDashboardMetricsByIds
} from 'src/selectors/dashboardMetrics';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _uniq from 'lodash/uniq';
import _findIndex from 'lodash/findIndex';
import { createSelector } from 'reselect';
import { getLastVisitedDashboardIds } from 'src/selectors/lastVisited';
import { getMetricsFromStore } from 'src/selectors/metrics';

export const getDashboardsFromStore = (state) => state.entities.dashboards.byId;
const getDashboardAsyncStatesByAction = (state) => state.entities.dashboards.asyncStatesByAction;

export const getDashboardByIdFromStore = (state, id) => {
    const dashboards = getDashboardsFromStore(state);
    return dashboards[id] || false;
};

export const getDashboardIdsFromStore = (state) => state.entities.dashboards.allIds;

export const selectDashboards = createSelector(
    [
        getDashboardIdsFromStore,
        getDashboardsFromStore
    ],
    (dashboardIds, dashboards) => dashboardIds.map((dashboardId) => dashboards[dashboardId]).sort(makeStringSorter('name', 'asc'))
);

export const makeSelectAllDashboardIdAndName = () => createSelector(
    [
        selectDashboards
    ],
    (dashboards) => dashboards.map((dashboard) => ({ id: dashboard.id, label: dashboard.name }))
);

export const selectDashboardById = createSelector(
    [
        getDashboardByIdFromStore
    ],
    (dashboard) => dashboard
);

export const makeSelectDashboardById = () => createSelector(
    [
        getDashboardByIdFromStore
    ],
    (dashboard) => dashboard
);

export const makeSelectDashboardsByIds = () => createSelector(
    [
        getDashboardsFromStore,
        (_, dashboardIds) => dashboardIds,
    ],
    (dashboards, dashboardIds) => dashboardIds.map((dashboardId) => dashboards[dashboardId])
);

export const makeSelectDashboardsByFolderId = () => createSelector(
    [
        selectDashboards,
        (_, folderId) => folderId,
        (_x, _y, sortBy) => sortBy,
        (_x, _y, _z, sortDir) => sortDir,

    ],
    (dashboards, folderId, sortBy = 'name', sortDir = 'asc') => (
        dashboards.filter((dashboard) => dashboard.folderId === folderId).sort(makeStringSorter(sortBy, sortDir))
    )
);

export const makeSelectDashboardIdsByFolderId = () => {
    const selectDashboardsByFolderId = makeSelectDashboardsByFolderId();
    return createSelector(
        [
            selectDashboardsByFolderId
        ],
        (dashboards) => dashboards.map((dashboard) => dashboard.id)
    );
};

export const makeSelectListItemIdsForFoldersAndDashboards = () => createSelector(
    [
        (folderIds) => folderIds,
        (_, dashboardIds) => dashboardIds
    ],
    (folderIds, dashboardIds) => {
        const dashboardItemIds = dashboardIds.map((dashboardId) => `dashboardListItem-${dashboardId}`);
        return dashboardItemIds.concat(folderIds.map((folderId) => `folderListItem-${folderId}`));
    }
);

export const selectLastVisitedDashboards = createSelector([
    getLastVisitedDashboardIds,
    getDashboardsFromStore
], (lastVisitedDashboardIds, dashboards) => {
    const lastVisitedDashboards = [];
    lastVisitedDashboardIds.forEach((dashboardId) => {
        if (dashboards[dashboardId]) {
            lastVisitedDashboards.push(dashboards[dashboardId]);
        }
    });
    return lastVisitedDashboards;
});

export const selectDashboardLayoutById = createSelector(
    [
        getDashboardMetricsLayoutsFromStore,
        (state, dashboardId) => getDashboardByIdFromStore(state, dashboardId).dashboardMetricIds
    ],
    (dashboardMetricsLayouts, dashboardMetricIds) => dashboardMetricIds.map((id) => dashboardMetricsLayouts[id])
);

export const selectSortedDashboardMetricIdsIndexById = createSelector(
    [
        (state, dashboardId) => selectDashboardLayoutById(state, dashboardId)
    ],
    (dashboardLayout) => dashboardLayout.sort((a, b) => {
        if (a.y > b.y) {
            return 1;
        }
        if (b.y > a.y) {
            return -1;
        }
        return 0;
    }).map((layout) => layout.id)
);

export const selectNextAndPreviousMetricIdsByDashboardIdAndDashboardMetricId = createSelector(
    [
        (state, dashboardId) => selectSortedDashboardMetricIdsIndexById(state, dashboardId),
        (x, y, dashboardMetricId) => dashboardMetricId
    ],
    (sortedDashboardMetricIdIndexById, dashboardMetricId) => {
        const nextAndPrevId = {
            next: undefined,
            previous: undefined
        };

        const index = _findIndex(sortedDashboardMetricIdIndexById, (id) => id === dashboardMetricId);
        const { length } = sortedDashboardMetricIdIndexById;

        if (index < 0) {
            return nextAndPrevId;
        }

        if (index > 0) {
            nextAndPrevId.previous = sortedDashboardMetricIdIndexById[index - 1];
        }

        if (index < length - 1) {
            nextAndPrevId.next = sortedDashboardMetricIdIndexById[index + 1];
        }

        return nextAndPrevId;
    }
);

export const makeSelectDashboardMetricIdsByDashboardIds = () => {
    const selectDashboardsByIds = makeSelectDashboardsByIds();
    return createSelector(
        [
            selectDashboardsByIds
        ],
        (dashboards) => {
            let combinedMetricIds = [];
            dashboards.forEach((dashboard) => {
                if (dashboard) {
                    combinedMetricIds = [...combinedMetricIds, ...dashboard.dashboardMetricIds];
                }
            });
            return combinedMetricIds;
        }
    );
};

export const makeSelectMetricIdsByDashboardMetricIds = () => {
    const selectDashboardMetricsByIds = makeSelectDashboardMetricsByIds();
    return createSelector(
        [
            selectDashboardMetricsByIds
        ],
        (dashboardMetrics) => {
            const combinedMetricIds = [];
            dashboardMetrics.forEach((dashboardMetric) => {
                combinedMetricIds.push(dashboardMetric.metricId);
            });
            return _uniq(combinedMetricIds);
        }
    );
};

export const makeSelectDashboardsMetricsByType = () => createSelector(
    [
        createSelector(
            [
                getDashboardMetricsFromStore,
                getMetricsFromStore,
                (_, dashboardMetricIds) => dashboardMetricIds
            ],
            (dashboardMetrics, metrics, dashboardMetricIds) => {
                let hasCustomMetric = false;
                const platformTypes = [];
                dashboardMetricIds.forEach((dashboardMetricId) => {
                    const dashboardMetric = dashboardMetrics[dashboardMetricId];
                    if (dashboardMetric) {
                        const metric = metrics[dashboardMetric.metricId];
                        if (metric) {
                            if (metric.isCustomMetric) {
                                hasCustomMetric = true;
                            }
                            platformTypes.push(metric.platformType);
                        }
                    }
                });
                return { platformTypes, hasCustomMetric };
            }
        )
    ],
    ({ platformTypes, hasCustomMetric }) => ({
        platformTypesOfMetrics: orderPlatformTypesByUsage(platformTypes),
        hasCustomMetric
    })
);

export const selectIsDashboardGridLoading = (state, dashboardId) => {
    const asyncStatesByAction = getDashboardAsyncStatesByAction(state);
    return _get(asyncStatesByAction, ['gridLoading', dashboardId, 'isPending'], false);
};

export const selectIsDashboardClonePending = (state, dashboardId) => {
    const asyncStatesByAction = getDashboardAsyncStatesByAction(state);
    return _get(asyncStatesByAction, ['clone', dashboardId, 'isPending'], false);
};

export const makeSelectDashboardIdsByMetricId = () => createSelector(
    [
        getDashboardMetricsFromStore,
        getMetricsFromStore,
        (_, metricId) => metricId
    ],
    (dashboardMetrics, metrics, metricId) => {
        const dashboardIds = [];
        Object.values(dashboardMetrics).forEach((dashboardMetric) => {
            if (dashboardIds.indexOf(dashboardMetric.dashboardId) === -1 && _has(metrics, dashboardMetric.metricId)) {
                const metric = metrics[dashboardMetric.metricId];
                if (metric.id === metricId) {
                    dashboardIds.push(dashboardMetric.dashboardId);
                }
            }
        });
        return dashboardIds;
    }
);
