import { createAction } from '@reduxjs/toolkit';
import { createFormAction } from 'redux-form-saga';

export const adAccountUseCaseSetRequest = createAction('AD_ACCOUNT_USE_CASE_SET_REQUEST', (formValues) => ({ payload: { formValues } }));
export const adAccountUseCaseSetSuccess = createAction('AD_ACCOUNT_USE_CASE_SET_SUCCESS', (adAccountId) => ({ payload: { adAccountId } }));
export const adAccountUseCaseSetError = createAction('AD_ACCOUNT_USE_CASE_SET_ERROR', (error) => ({ payload: error }));

export const adAccountUseCaseSetRequestFormAction = createFormAction(
    adAccountUseCaseSetRequest,
    [adAccountUseCaseSetSuccess.type, adAccountUseCaseSetError.type]
);

export const bulkAdAccountsUseCasesUpdateRequest = createAction('BULK_AD_ACCOUNTS_USE_CASES_UPDATE_REQUEST', (checkedAndUncheckedUseCasesPerAdAccount) => ({ payload: { checkedAndUncheckedUseCasesPerAdAccount } }));
export const bulkAdAccountsUseCasesUpdateSuccess = createAction('AD_ACCOUNT_USE_CASE_SET_SUCCESS', (adAccountId) => ({ payload: { adAccountId } }));
export const bulkAdAccountsUseCasesUpdateError = createAction('AD_ACCOUNT_USE_CASE_SET_ERROR', (error) => ({ payload: error }));

export const adAccountUseCaseRemoveRequest = createAction('AD_ACCOUNT_USE_CASE_REMOVE_REQUEST', (accountId, useCaseId) => ({ payload: { accountId, useCaseId } }));
export const adAccountUseCaseRemoveSuccess = createAction('AD_ACCOUNT_USE_CASE_REMOVE_SUCCESS', (accountId, useCaseId) => ({ payload: { accountId, useCaseId } }));
export const adAccountUseCaseRemoveError = createAction('AD_ACCOUNT_USE_CASE_REMOVE_ERROR', (accountId, useCaseId, error) => ({ payload: { accountId, useCaseId, error } }));
