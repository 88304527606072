import {
    accept,
    base,
    createFetch,
    createStack,
    init,
    method,
    params,
    requestInfo,
    header
} from 'http-client';
import config from 'react-global-configuration';
import { requestUrl } from 'src/config';
import { parseWithOwnErrorText, exceptionOnNotOk } from 'src/requestHandling/utils';

export default (parameters = {}, parser = 'json', headers = null) => {
    const parametersCopy = Object.assign({}, parameters);
    const csrfToken = config.get('csrfToken', false);
    if (csrfToken) {
        Object.assign(parametersCopy, { csrfToken });
    }
    const args = [
        base(requestUrl),
        accept('application/json'),
        init('credentials', 'include'),
        requestInfo(),
        method('POST'),
        params(parametersCopy),
        parseWithOwnErrorText(parser, parser === 'json' ? 'jsonData' : 'blobData'),
        exceptionOnNotOk(parser)
    ];

    if (headers) {
        Object.keys(headers).forEach((name) => {
            args.push(header(name, headers[name]));
        });
    }
    const stack = createStack(
        ...args
    );
    return createFetch(stack);
};
