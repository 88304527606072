import {
    makeSelectAdditionalFilterByContext, makeSelectDateByContext, makeSelectProfileByContext
} from 'src/selectors/filterSelectors';
import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { selectDefaultTimezoneFromAccountSettings } from 'src/selectors/loggedInUser';
import { makeSelectMetricById } from 'src/selectors/metrics';
import { makeSelectVisualizationById } from 'src/selectors/visualizations';
import { createShallowEqualSelector } from './utils';

export const getDashboardMetricsFromStore = (state) => state.entities.dashboardMetrics.byId;
const getDashboardMetricsSettingsFromStore = (state) => state.entities.dashboardMetrics.settingsById;
export const getDashboardMetricsLayoutsFromStore = (state) => state.entities.dashboardMetrics.layoutsById;
const getDashboardMetricAsyncStatesFromStore = (state) => state.entities.dashboardMetrics.asyncStatesByAction;

export const getDashboardMetricByIdFromStore = (state, id) => {
    const dashboardMetrics = getDashboardMetricsFromStore(state);
    return _get(dashboardMetrics, id, false);
};

const getDashboardMetricSettingsByIdFromStore = (state, id) => {
    const dashboardMetricsSettings = getDashboardMetricsSettingsFromStore(state);
    return dashboardMetricsSettings[id] || false;
};

const makeSelectDashboardMetricSettingsById = () => {
    const selectVisualizationById = makeSelectVisualizationById();

    return createShallowEqualSelector(
        (state, id) => {
            const settings = getDashboardMetricSettingsByIdFromStore(state, id);
            let visualization = null;

            if (settings && settings.visualizationId) {
                visualization = selectVisualizationById(state, settings.visualizationId);
            }
            // check for valid customProfileBinding
            const isCustomProfileSelectionInvalid = false;
            // if (_has(settings, 'isCustomProfileSelected') && settings.isCustomProfileSelected === true && settings.settingProfileSelection) {
            //     const customProfile = selectProfileOrGroupBySelection(state, settings.settingProfileSelection);
            //     if (customProfile === false) {
            //         isCustomProfileSelectionInvalid = true;
            //     }
            // }
            return {
                settings,
                visualization,
                isCustomProfileSelectionInvalid
            };
        },
        ({ settings, visualization, isCustomProfileSelectionInvalid }) => {
            if (isCustomProfileSelectionInvalid) {
                const manipulatedSettings = Object.assign({}, settings, { isCustomProfileSelected: false, settingProfileSelection: false });
                return Object.assign({}, manipulatedSettings, { visualization });
            }
            return Object.assign({}, settings, { visualization });
        }
    );
};

function createDerivedDashboardMetric(dashboardMetric, settings, metric) {
    const displayName = settings.alias || metric.name;
    return Object.assign({}, dashboardMetric, { settings, metric, displayName });
}

export const makeSelectDashboardMetricById = () => {
    const selectMetricById = makeSelectMetricById();
    const selectDashboardMetricSettingsById = makeSelectDashboardMetricSettingsById();

    return createShallowEqualSelector(
        [
            (state, id) => {
                const dashboardMetric = getDashboardMetricByIdFromStore(state, id);
                const metric = selectMetricById(state, dashboardMetric.metricId);
                return { dashboardMetric, metric };
            },
            selectDashboardMetricSettingsById,
        ],
        ({ dashboardMetric, metric }, settings) => {
            if (dashboardMetric) {
                return createDerivedDashboardMetric(dashboardMetric, settings, metric);
            }
            return false;
        }
    );
};

export const makeSelectDashboardMetricsByIds = () => {
    // each entity needs its own selector
    const selectorCache = {};
    const getSelector = (id) => {
        const selector = _get(selectorCache, id);
        if (selector) { return selector; }
        Object.assign(selectorCache, { [id]: makeSelectDashboardMetricById() });
        return selectorCache[id];
    };
    return createShallowEqualSelector(
        [
            (state, ids) => {
                const entities = {};
                ids.forEach((id) => Object.assign(entities, { [id]: getSelector(id)(state, id) }));
                return entities;
            },
            (state, ids) => ids
        ],
        (dashboardMetricsById, ids) => ids.map((id) => dashboardMetricsById[id])
    );
};

export const selectIsDeletePending = createSelector(
    [
        (state) => getDashboardMetricAsyncStatesFromStore(state).delete,
        (_, id) => id
    ],
    (asyncActions, dashboardMetricId) => _get(asyncActions, [dashboardMetricId, 'isPending'], false)
);

export const selectIsUpdateNotePending = createSelector(
    [
        (state) => getDashboardMetricAsyncStatesFromStore(state).updateNotes,
        (_, id) => id
    ],
    (asyncActions, dashboardMetricId) => _get(asyncActions, [dashboardMetricId, 'isPending'], false)
);

const defaultSelection = {
    groupIds: ['0'],
    profileIds: []
};

export const makeSelectInitialDashboardMetricSettings = () => {
    const selectDateByContext = makeSelectDateByContext();
    const selectAdditionalFilterByContext = makeSelectAdditionalFilterByContext();
    const selectProfileByContext = makeSelectProfileByContext();
    return createSelector(
        [
            selectDateByContext,
            selectProfileByContext,
            selectAdditionalFilterByContext,
            (_, __, dashboardMetric) => dashboardMetric,
            (_, __, ___, isTable) => isTable,
            selectDefaultTimezoneFromAccountSettings
        ],
        (selectedDate, selectedProfileSelection, selectedAdditionalFilter, dashboardMetric, isTable, defaultTimezoneFromSettings) => {
            if (dashboardMetric) {
                const {
                    metric,
                    settings
                } = dashboardMetric;
                const formInitialValues = {
                    dashboardId: dashboardMetric.dashboardId,
                    dashboardMetricId: dashboardMetric.id,
                    isCustomDateSelected: settings.isCustomDateSelected,
                    settingProfileSelection: settings.settingProfileSelection || selectedProfileSelection || defaultSelection,
                    isCustomProfileSelected: settings.isCustomProfileSelected,
                    settingAdditionalFilterSelection: settings.settingAdditionalFilterSelection !== null ? settings.settingAdditionalFilterSelection : selectedAdditionalFilter,
                    isCustomAdditionalFilterSelected: settings.isCustomAdditionalFilterSelected,
                    settingDateSelection: settings.settingDateSelection || selectedDate || {
                        dynamicDate: 'last 7 days',
                        interval: 'daily',
                        timezone: defaultTimezoneFromSettings
                    },
                    alias: settings.alias,
                    visualizationId: settings.visualizationId || metric.visualizationId,
                    inheritTimezone: settings.inheritTimezone
                };

                if (isTable) {
                    const tableColumns = metric.config.metaData.columns;
                    const defaultHiddenColumnIds = [];
                    tableColumns.forEach((column) => {
                        const isHidden = column.hidden || false;
                        if (isHidden) {
                            defaultHiddenColumnIds.push(column.id);
                        }
                    });

                    const {
                        hideColumns,
                        limit,
                        sortBy,
                        sortDir
                    } = settings.settingAdditional;

                    formInitialValues.hideColumns = hideColumns || defaultHiddenColumnIds;
                    formInitialValues.limit = limit || 0;
                    formInitialValues.sortBy = sortBy || _get(metric, 'config.metaData.sortBy') || _get(metric, 'config.metaData.columns[0].id');
                    formInitialValues.sortDir = sortDir || _get(metric, 'config.metaData.sortDir') || 'ASC';
                }

                return formInitialValues;
            }
            return false;
        }
    );
};

export const selectDashboardMetricLayoutById = createSelector(
    [
        getDashboardMetricsLayoutsFromStore,
        (_, dashboardMetricId) => dashboardMetricId
    ],
    (layoutById, dashboardMetricId) => layoutById[dashboardMetricId]
);
