import { createAction } from '@reduxjs/toolkit';
import { createFormAction } from 'redux-form-saga';

export const authUserRemoveRequest = createAction('AUTH_USER_REMOVE_REQUEST', ({ id, environment }) => ({ payload: { id, environment } }));
export const authUserRemoveSuccess = createAction('AUTH_USER_REMOVE_SUCCESS', (id) => ({ payload: { id } }));
export const authUserRemoveError = createAction('AUTH_USER_REMOVE_ERROR', (error) => ({ payload: error }));

export const authUserRemoveRequestFormAction = createFormAction(authUserRemoveRequest, [
    authUserRemoveSuccess.type, authUserRemoveError.type
]);

export const clearAuthUserConnectStateFromReducer = createAction('CLEAR_AUTH_USER_CONNECT_STATE_FROM_REDUCER', (identifier) => ({ payload: { identifier } }));
