import _get from 'lodash/get';
import _has from 'lodash/has';
import _isString from 'lodash/isString';
import {
    authenticationRoute,
    profilesRoute,
    dataPushTasksRoute,
    folderRoute,
    dashboardRoute
} from 'src/routePaths';

export const ANALYSIS = 'analysis';
export const ACCOUNTS = 'accounts';
export const DATA_SOURCES = 'dataSources';
export const POSTS = 'posts';
export const INTEGRATION = 'integration';
export const NOTIFICATION = 'notifications';
export const USER_PERSONAL = 'userPersonal';
export const USER_ADMIN = 'userAdmin';

export const isAlsoActiveOnRoutes = (routes, location) => {
    if (!routes) {
        return false;
    }
    const { pathname } = location;
    const normalizedPathname = pathname.charAt(pathname.length - 1) !== '/' ? `${pathname}/` : pathname;
    return routes.some((routeToCheck) => {
        let normalizedRouteToCheck = routeToCheck;

        if (normalizedRouteToCheck.charAt(0) !== '/') {
            normalizedRouteToCheck = `/${normalizedRouteToCheck}`;
        }
        if (normalizedRouteToCheck.charAt(normalizedRouteToCheck.length - 1) !== '/') {
            normalizedRouteToCheck = `${normalizedRouteToCheck}/`;
        }
        return normalizedPathname.startsWith(normalizedRouteToCheck);
    });
};

export const isExternalLink = (url) => {
    if (url && _isString(url) && (url.substr(0, 7) === 'http://' || url.substr(0, 8) === 'https://')) {
        return true;
    }
    return false;
};

export const getPathForFolderOfType = (id, type) => {
    if (id === type) {
        return `/${type}-dashboards`;
    }
    return `${folderRoute}/${id}`;
};

export const getPathForFolderParentByType = (parentId, type) => {
    if (parentId === 'root') {
        return `/${type}-dashboards`;
    }
    return `${folderRoute}/${parentId}`;
};

export const getDashboardRoute = (dashboardId) => `${dashboardRoute}/${dashboardId}`;

export const getAuthenticationsWithUnfulfilledUseCasesOpenRoute = () => (
    { pathname: authenticationRoute, state: { openBrokenProfileUseCaseMenu: true } }
);

export const getNotificationLinkFromNotificationContent = (content) => {
    // If the notification has a dashboard id, link to the dashboard
    if (_has(content, 'metaData.dashboardId')) {
        return getDashboardRoute(content.metaData.dashboardId);
    }

    // If notification is about unfulfilled private stats use cases, link to authentication section
    if (_get(content, 'type', null) === 'brokenProfileUseCases') {
        return getAuthenticationsWithUnfulfilledUseCasesOpenRoute();
    }

    if (_has(content, 'metaData.dataPushTaskId')) {
        return { pathname: `${dataPushTasksRoute}/${content.metaData.dataPushTaskId}` };
    }

    return {
        pathname: profilesRoute
    };
};

export const getDataPushLogDetailRoute = (dataPushTaskId, logId) => `${dataPushTasksRoute}/${dataPushTaskId}/log/${logId}`;
