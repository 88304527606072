import _get from 'lodash/get';
import _has from 'lodash/has';
import moment from 'moment';
import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';
import _uniq from 'lodash/uniq';
import _pick from 'lodash/pick';
import _difference from 'lodash/difference';

export const QUICK_ANALYSIS = 'quickAnalysis';
export const POST_TAGGING = 'postTagging';
export const METRIC_BUILDER = 'metricBuilder';
export const DISCOVER_PREVIEW = 'discoverPreview';
export const DASHBOARD = 'dashboard';
export const getDashboardContext = (dashboardId) => `dashboard-${dashboardId}`;

export const defaultPostTextExcludeState = { values: [], conjunction: 'any' };
export const defaultPostTextState = { values: [], conjunction: 'any' };
export const defaultAdCampaignState = { values: [], conjunction: 'any' };
export const defaultPostTagState = { values: [], conjunction: 'any', untagged: false };

export const defaultDateState = {
    from: '2016-02-01',
    to: '2016-02-15',
    interval: 'daily',
    timezone: 'UTC' // this is hardcoded, it gets replaced with account settings timezone in the selector
};
export const defaultProfileState = { profileIds: [], groupIds: ['0'], adAccountIds: [] };
export const getDefaultDate = () => (Object.assign({}, defaultDateState, {
    from: moment().subtract(15, 'days').format('YYYY-MM-DD'),
    to: moment().subtract(1, 'days').format('YYYY-MM-DD'),
}));

export const getPostTagFilter = (postTagId) => ({ values: [postTagId], conjunction: 'any', untagged: false });

export const getGroupIdOrProfileIds = (globalSelectedProfileOrGroup) => {
    if (globalSelectedProfileOrGroup.type === 'group') {
        return { groupId: globalSelectedProfileOrGroup.id };
    }
    if (globalSelectedProfileOrGroup.type === 'profile') {
        return { profileIds: [globalSelectedProfileOrGroup.id] };
    }
    return {};
};

const hasValues = (filter) => (_get(filter, 'values', [])).length > 0;

export const isPostTagSelected = (postTag) => (postTag && (hasValues(postTag) || postTag.untagged === true));
export const isPostTextSelected = (postText) => (postText && hasValues(postText));
export const isAdCampaignSelected = (adCampaign) => (adCampaign && hasValues(adCampaign));
export const isPostTextExcludeSelected = (postTextExclude) => (postTextExclude && hasValues(postTextExclude));

export const getMappingAdditionalFilterToUrlParams = () => ({
    postTag: {
        values: 'postTag',
        conjunction: 'postTagConjunction',
        untagged: 'untagged'
    },
    postText: {
        conjunction: 'postTextConjunction',
        values: 'postText'
    },
    postTextExclude: {
        conjunction: 'postTextExcludeConjunction',
        values: 'postTextExclude'
    },
    adCampaign: {
        conjunction: 'adCampaignConjunction',
        values: 'adCampaign'
    }
});

// dashboard setting Parsers
export function convertSettingDateSelection(settingDateSelection) {
    if (!settingDateSelection) {
        return false;
    }
    const {
        to, from, interval, dynamicDate, timezone
    } = settingDateSelection;
    if (dynamicDate && interval) {
        return {
            dynamicDate,
            interval,
            timezone
        };
    } if (to && from && interval) {
        return {
            to,
            from,
            interval,
            timezone
        };
    }
    return false;
}

export const getAdditionalFilterCount = ({
    postText, postTag, postTextExclude, adCampaign
}) => {
    let additionalFiltersCount = 0;
    if (isPostTextSelected(postText) || isPostTextExcludeSelected(postTextExclude)) {
        additionalFiltersCount += 1;
    }

    if (isAdCampaignSelected(adCampaign)) {
        additionalFiltersCount += 1;
    }

    if (isPostTagSelected(postTag)) {
        additionalFiltersCount += 1;
    }
    return additionalFiltersCount;
};

export const profileSelectionToParams = ({ groupIds, profileIds, adAccountIds }) => {
    const profileSelections = profileIds.map((profileId) => `profile-${profileId}`);
    const groupSelections = groupIds.map((groupId) => `group-${groupId}`);
    const adAccountSelection = adAccountIds.map((adAccountId) => `adAccount-${adAccountId}`);
    return profileSelections.concat(groupSelections).concat(adAccountSelection).join(' ');
};

export const additionalFilterToParams = ({
    postTag, postText, postTextExclude, adCampaign
}) => {
    const mappings = getMappingAdditionalFilterToUrlParams();

    const postTextParams = { [mappings.postText.values]: '' };
    if (postText) {
        Object.assign(postTextParams, {
            [mappings.postText.values]: postText.values,
            [mappings.postText.conjunction]: postText.conjunction
        });
    }

    const postTextExcludeParams = { [mappings.postTextExclude.values]: '' };
    if (postTextExclude) {
        Object.assign(postTextExcludeParams, {
            [mappings.postTextExclude.values]: postTextExclude.values,
            [mappings.postTextExclude.conjunction]: postTextExclude.conjunction
        });
    }

    const postTagParams = { [mappings.postTag.values]: '' };
    if (postTag) {
        if (postTag.values.length > 0) {
            Object.assign(postTagParams, {
                [mappings.postTag.values]: postTag.values,
                [mappings.postTag.conjunction]: postTag.conjunction,
            });
        }
        if (postTag.untagged === true) {
            Object.assign(postTagParams, {
                [mappings.postTag.untagged]: postTag.untagged
            });
        }
    }

    const adCampaignParams = { [mappings.adCampaign.values]: '' };
    if (adCampaign) {
        Object.assign(adCampaignParams, {
            [mappings.adCampaign.values]: adCampaign.values,
            [mappings.adCampaign.conjunction]: adCampaign.conjunction
        });
    }

    return Object.assign({}, postTextParams, postTagParams, postTextExcludeParams, adCampaignParams);
};

export const generateInitialFilterSelectors = () => {
    const initialGroupId = '0';
    return {
        defaultSelectors: {
            profile: {
                groupIds: [initialGroupId],
                profileIds: [],
                adAccountIds: []
            },
            date: getDefaultDate(),
            postText: defaultPostTextState,
            postTextExclude: defaultPostTextExcludeState,
            postTag: defaultPostTagState,
        },
        contextAwareFilterSelectors: {}
    };
};

const transformValuesParamToValuesArray = (valuesParam) => {
    if (_isString(valuesParam) && valuesParam.length > 0) {
        return [valuesParam];
    }

    if (_isArray(valuesParam)) {
        return _uniq(valuesParam);
    }
    return [];
};

export const computeNewStateForValuesAndConjunction = (prevState, defaultState, query, valuesParam, conjunctionParam) => {
    if (_has(query, valuesParam)) {
        const valuesArray = transformValuesParamToValuesArray(query[valuesParam]);
        if (valuesArray.length > 0) {
            const newState = Object.assign({}, defaultState, { values: valuesArray });
            if (_has(query, conjunctionParam)) {
                Object.assign(newState, { conjunction: query[conjunctionParam] });
            }
            return newState;
        }
        return Object.assign({}, defaultState);
    }
    return prevState;
};

export const selectedDateFromDefaultContext = (selectedDate, context) => {
    if (selectedDate && context && _get(context, 'isDefault')) {
        return Object.assign({}, selectedDate, { timezone: _get(context, 'defaultTimezone') });
    }
    return selectedDate;
};

const getDateFromQueryParam = (queryParam) => {
    const dynamicDate = _get(queryParam, 'dynamicDate', false);
    return dynamicDate ? { dynamicDate } : { from: _get(queryParam, 'from'), to: _get(queryParam, 'to') };
};

export const getMissingDateParams = (locationQueryParams, existingQueryParams) => {
    const dynamicDate = _get(locationQueryParams, 'dynamicDate', false);
    const from = _get(locationQueryParams, 'from', false);
    const to = _get(locationQueryParams, 'to', false);
    if (!dynamicDate && (!from || !to)) {
        return getDateFromQueryParam(existingQueryParams);
    }
    return {};
};

const dateFilterKeys = ['from', 'to', 'dynamicDate'];

const getOtherMissingParamsOrFalse = (locationQueryParams, existingQueryParams) => {
    const requiredKeys = _difference(Object.keys(existingQueryParams), dateFilterKeys);
    const availableKeys = Object.keys(locationQueryParams);
    const missingKeys = requiredKeys.filter((el) => !availableKeys.includes(el));
    if (missingKeys.length > 0) {
        return _pick(existingQueryParams, missingKeys);
    }
    return {};
};

export const getMissingParams = (locationQueryParams, existingQueryParams) => {
    const dateMissingParams = getMissingDateParams(locationQueryParams, existingQueryParams);
    const otherMissingParams = getOtherMissingParamsOrFalse(locationQueryParams, existingQueryParams);
    return Object.assign(dateMissingParams, otherMissingParams);
};
