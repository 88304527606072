import _forOwn from 'lodash/forOwn';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { createSelector } from 'reselect';
import { defaultNameStringOrderWithExcludedPrefix, makeOrderBySorter, makeSearchQueryFilter } from 'src/selectors/accounts';
import { selectUserCreatedGroups } from 'src/selectors/groups';
import { makeSelectProfileUseCasesAuthUsersByProfileId } from 'src/selectors/profileUseCasesAuthUsers';
import { makeSelectAuthUsersAuthProfileUseCasesByProfileId } from 'src/selectors/authUsersAuthProfileUseCases';
import { makeSelectProfileUseCasesByProfileId } from 'src/selectors/accountUseCases';
import {
    createDeepEqualSelector,
    createShallowEqualSelector,
    defaultLoadingState,
    makeGroupFilter,
    makePlatformTypeFilter,
    makePropertyExtractor
} from 'src/selectors/utils';
import { generateAccountTempId } from 'src/utils/accountSearch';
import { getFoundedEntities } from 'src/utils/array';

export const getProfileIdsFromStore = (state) => state.entities.profiles.allIds;
export const getProfilesFromStore = (state) => state.entities.profiles.byId;
export const getAsyncStates = (state) => state.entities.profiles.asyncStates;

const getProfileByIdFromStore = (state, profileId) => {
    const profiles = getProfilesFromStore(state);
    return profiles[profileId] || false;
};

export const makeSelectProfileById = () => {
    const selectProfileUseCasesByProfileId = makeSelectProfileUseCasesByProfileId();
    const selectProfileUseCasesAuthUsersByProfileId = makeSelectProfileUseCasesAuthUsersByProfileId();
    const selectAuthUsersAuthProfileUseCasesByProfileId = makeSelectAuthUsersAuthProfileUseCasesByProfileId();
    return createShallowEqualSelector(
        [
            (state, id) => {
                const profile = getProfileByIdFromStore(state, id);
                const useCases = selectProfileUseCasesByProfileId(state, id);
                const accountUseCasesAuthUsers = selectProfileUseCasesAuthUsersByProfileId(state, id);
                const authUsersAuthAccountUseCases = selectAuthUsersAuthProfileUseCasesByProfileId(state, id);
                return {
                    profile, useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases
                };
            }
        ],
        ({
            profile, useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases
        }) => {
            if (profile) {
                return Object.assign({}, profile, { useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases });
            }
            return false;
        }
    );
};

export const makeSelectProfilesByIds = () => {
    // each entity needs it's own selector
    const selectorCache = {};
    const getSelector = (id) => {
        const selector = _get(selectorCache, id);
        if (selector) { return selector; }
        Object.assign(selectorCache, { [id]: makeSelectProfileById() });
        return selectorCache[id];
    };
    return createShallowEqualSelector(
        [
            (state, ids) => {
                const entities = {};
                ids.forEach((id) => Object.assign(entities, { [id]: getSelector(id)(state, id) }));
                return entities;
            },
            (state, ids) => ids
        ],
        (profilesById, ids) => getFoundedEntities(ids, profilesById)
    );
};

// the purpose of this selector is to select only profiles without useCases and authUserUseCases
export const makeSelectOnlyProfiles = () => createSelector(
    [
        getProfileIdsFromStore,
        getProfilesFromStore
    ],
    (allIds, profiles) => allIds.map((id) => profiles[id])
);

export const makeSelectOnlyProfileById = () => createSelector(
    [
        getProfilesFromStore,
        (_, id) => id,
    ],
    (profiles, id) => profiles[id] || null
);

export const makeSelectOnlyProfilesByIds = () => createSelector(
    [
        getProfilesFromStore,
        (_, ids) => ids,
    ],
    (profilesById, ids) => getFoundedEntities(ids, profilesById)
);

export const makeSelectProfiles = () => {
    const selectProfilesById = makeSelectProfilesByIds();
    return (state) => selectProfilesById(state, getProfileIdsFromStore(state));
};

export const makeSelectProfileByTempId = () => {
    const selectProfiles = makeSelectProfiles();
    return createSelector(
        [
            selectProfiles,
            (_, id) => id
        ],
        (profiles, id) => {
            const index = profiles.map((profile) => generateAccountTempId(profile)).indexOf(id);
            return index > -1 ? profiles[index] : null;
        }
    );
};

export const makeSelectProfilesFilteredByQuery = () => {
    const selectProfiles = makeSelectProfiles();
    const searchQueryFilter = makeSearchQueryFilter();
    return createSelector(
        [
            selectProfiles,
            (_, filterQuery) => filterQuery
        ],
        (profiles, filterQuery) => searchQueryFilter(profiles, filterQuery).sort(defaultNameStringOrderWithExcludedPrefix())
    );
};

export const makeSelectProfileIdsWithListFiltersApplied = () => {
    const selectProfiles = makeSelectOnlyProfiles();
    const searchQueryFilter = makeSearchQueryFilter();
    const sorter = makeOrderBySorter();
    const idsExtractor = makePropertyExtractor('id');
    const groupFilter = makeGroupFilter();
    const platformTypeFilter = makePlatformTypeFilter();

    return createDeepEqualSelector([(state, filterBy, platformType, group, orderBy) => {
        const allProfiles = selectProfiles(state);
        const queryFiltered = searchQueryFilter(allProfiles, filterBy);
        const groupFiltered = groupFilter(queryFiltered, group);
        const platformTypeFiltered = platformTypeFilter(groupFiltered, platformType);
        const sorted = sorter(platformTypeFiltered, orderBy);
        return idsExtractor(sorted);
    }], (ids) => ids);
};

export const selectCustomColors = createSelector(
    [
        getProfilesFromStore
    ],
    (profiles) => {
        const customColors = {};
        _forOwn(profiles, (profile) => {
            const color = _get(profile, 'color', null);
            if (color) {
                customColors[profile.id] = color;
            }
        });
        return customColors;
    }
);

export const selectFavorites = createSelector(
    [
        getProfilesFromStore,
    ],
    (profiles) => {
        const favorites = {};
        _forOwn(profiles, (profile) => {
            const favorite = _get(profile, 'favorite', false);
            if (favorite) {
                favorites[profile.id] = favorite;
            }
        });
        return favorites;
    }
);

export const makeSelectGroupIdsByAdAccountId = () => createSelector(
    [
        selectUserCreatedGroups,
        (_, adAccountId) => adAccountId
    ],
    (userCreatedGroups, adAccountId) => {
        const groupIdsByAdAccountId = [];
        userCreatedGroups.forEach((group) => {
            if (group.adAccountIds.indexOf(adAccountId) >= 0) {
                groupIdsByAdAccountId.push(group.id);
            }
        });
        return groupIdsByAdAccountId.sort();
    }
);

export const makeSelectGroupsIdsByProfileId = () => createSelector(
    [
        selectUserCreatedGroups,
        (_, profileId) => profileId
    ],
    (userCreatedGroups, profileId) => {
        const groupIdsByProfileId = [];
        userCreatedGroups.forEach((group) => {
            if (group.profileIds.indexOf(profileId) >= 0) {
                groupIdsByProfileId.push(group.id);
            }
        });
        return groupIdsByProfileId.sort();
    }
);

const calculateSelectedGroupsAndIndeterminateGroups = (groupIdsByProfileIds, profileCount) => {
    const groupIdCounts = {};
    Object.values(groupIdsByProfileIds).forEach((groupIdsByProfile) => {
        groupIdsByProfile.forEach((groupId) => {
            if (_has(groupIdCounts, groupId)) {
                groupIdCounts[groupId] += 1;
            } else {
                groupIdCounts[groupId] = 1;
            }
        });
    });
    const selectedGroupIds = [];
    const indeterminateGroupIds = [];
    Object.keys(groupIdCounts).forEach((groupId) => {
        const groupIdCount = groupIdCounts[groupId];
        if (groupIdCount === profileCount) {
            selectedGroupIds.push(groupId);
        } else {
            indeterminateGroupIds.push(groupId);
        }
    });
    return {
        selectedGroupIds,
        indeterminateGroupIds
    };
};

const makeSelectGroupsIdsByAccountIdsWithIndeterminatedGroups = (getSelector) => createShallowEqualSelector(
    [
        (state, accountIds) => {
            const entities = {};
            accountIds.forEach((accountId) => Object.assign(entities, { [accountId]: getSelector(accountId)(state, accountId) }));
            return entities;
        },
        (state, ids) => ids
    ],
    (groupIdsByAccountIds, accountIds) => calculateSelectedGroupsAndIndeterminateGroups(groupIdsByAccountIds, accountIds.length)
);

export const makeSelectGroupsIdsByAdAccountIdsWithIndeterminatedGroups = () => {
    const selectorCache = {};
    const getSelector = (adAccountId) => {
        const selector = _get(selectorCache, adAccountId);
        if (selector) { return selector; }
        Object.assign(selectorCache, { [adAccountId]: makeSelectGroupIdsByAdAccountId() });
        return selectorCache[adAccountId];
    };
    return makeSelectGroupsIdsByAccountIdsWithIndeterminatedGroups(getSelector);
};

export const makeSelectGroupsIdsByProfileIdsWithIndeterminatedGroups = () => {
    const selectorCache = {};
    const getSelector = (profileId) => {
        const selector = _get(selectorCache, profileId);
        if (selector) { return selector; }
        Object.assign(selectorCache, { [profileId]: makeSelectGroupsIdsByProfileId() });
        return selectorCache[profileId];
    };
    return makeSelectGroupsIdsByAccountIdsWithIndeterminatedGroups(getSelector);
};

export const makeSelectNetworkOfMostProfiles = (defaultNetwork = 'facebook') => createSelector(
    [
        getProfilesFromStore
    ],
    (profiles) => {
        const networkCount = {};
        const profileIds = Object.keys(profiles);

        profileIds.forEach((profileId) => {
            const profile = profiles[profileId];
            const network = profile.platformType;
            if (_has(networkCount, network)) {
                networkCount[network] += 1;
            } else {
                networkCount[network] = 1;
            }
        });
        let localDefaultNetwork = defaultNetwork;
        let localMax = 0;
        const networks = Object.keys(networkCount);
        networks.forEach((network) => {
            const count = networkCount[network];
            if (count > localMax) {
                localMax = count;
                localDefaultNetwork = network;
            }
        });
        return localDefaultNetwork;
    }
);

export const selectProfileIds = (state) => getProfileIdsFromStore(state);

export const selectProfileAddingState = createSelector(
    [
        getAsyncStates,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, ['adding', id], defaultLoadingState)
);

export const selectIsBulkProfileAdding = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['bulkAdding', 'state', 'isPending'], false)
);

export const makeSelectIsInstagramPlatformUsernameUpdating = () => createSelector(
    [
        getAsyncStates,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, ['instagramPlatformUsernameUpdating', id, 'isPending'], false)
);

export const makeSelectIsTiktokPlatformUsernameUpdating = () => createSelector(
    [
        getAsyncStates,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, ['tiktokPlatformUsernameUpdating', id, 'isPending'], false)
);

export const selectPlatformTypeByProfileId = (state, profileId) => {
    const profile = getProfileByIdFromStore(state, profileId);

    if (profile) {
        return profile.platformType;
    }
    return '';
};
