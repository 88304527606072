import { getBaseUrl, mapTikTokCallbackUrlAlias } from 'src/sagas/utils';
import {
    googleOauthUrl,
    googleClientId,
    googleResponseType,
    googlePrompt,
    googleAccessType,
    tiktokClientKey,
    tiktokResponseType,
    tiktokOauthUrl,
    encodeUrlParams,
    snapchatClientId,
    snapchatResponseType,
    snapchatOauthUrl,
    linkedInResponseType,
    linkedInClientId,
    linkedInOauthUrl,
    threadsOauthUrl,
    threadsResponseType,
    threadsClientKey
} from 'src/config';
import { v4 as uuidv4 } from 'uuid';

export const getYoutubeAuthenticationCallbackUrl = (finalScopes) => {
    const redirectUri = `${getBaseUrl()}/client-index/handle-youtube-auth-user-connection-callback-url`;
    const params = {
        client_id: googleClientId,
        redirect_uri: redirectUri,
        scope: finalScopes,
        response_type: googleResponseType,
        prompt: googlePrompt,
        access_type: googleAccessType
    };

    return `${googleOauthUrl}?${encodeUrlParams(params)}`;
};

export const getTwitterAuthenticationCallbackUrl = (requestId, screenName = null) => {
    const baseUrl = getBaseUrl();
    const params = { requestId };
    if (screenName) {
        Object.assign(params, { screenName });
    }
    return `${baseUrl}/client-index/handle-twitter-auth-user-connection?${encodeUrlParams(params)}`;
};

export const getTiktokAuthenticationCallbackUrl = (finalScopes) => {
    const baseUrl = getBaseUrl();
    const alias = mapTikTokCallbackUrlAlias(baseUrl);
    const redirectUri = 'https://flan.facelift-cloud.com/tiktok/profile-gateway/v1/authRouter/';
    const params = {
        client_key: tiktokClientKey,
        response_type: tiktokResponseType,
        redirect_uri: redirectUri,
        scope: finalScopes,
        state: `_${alias}_`
    };
    return `${tiktokOauthUrl}?${encodeUrlParams(params)}`;
};

export const getThreadsAuthenticationCallbackUrl = (finalScopes) => {
    const redirectUri = `${getBaseUrl()}/client-index/handle-threads-auth-user-connection-callback-url`;
    const params = {
        client_id: threadsClientKey,
        response_type: threadsResponseType,
        redirect_uri: redirectUri,
        scope: finalScopes
    };
    return `${threadsOauthUrl}?${encodeUrlParams(params)}`;
};

export const getSnapchatAuthenticationCallbackUrl = (finalScopes) => {
    const params = {
        redirect_uri: `${getBaseUrl()}/client-index/handle-snapchat-auth-user-connection-callback-url`,
        client_id: snapchatClientId,
        response_type: snapchatResponseType,
        scope: finalScopes
    };
    return `${snapchatOauthUrl}?${encodeUrlParams(params)}`;
};

export const getLinkedInAuthenticationCallbackUrl = (finalScopes) => {
    const baseUrl = getBaseUrl();
    const redirectUrl = `${baseUrl}/client-index/handle-linked-in-auth-user-connection-callback-url`;
    const params = {
        response_type: linkedInResponseType,
        client_id: linkedInClientId,
        redirect_uri: redirectUrl,
        scope: finalScopes
    };
    return `${linkedInOauthUrl}?${encodeUrlParams(params)}`;
};

export const generateRandomTwitterRequestId = () => uuidv4();
