import { createSelector } from 'reselect';
import { getFoundedEntities } from 'src/utils/array';

export const getUseCasesIdsFromStore = (state) => state.entities.useCases.allIds;
export const getUseCasesFromStore = (state) => state.entities.useCases.byId;
export const basicUseCaseIdsByProfileType = {
    facebook: '1',
    instagram: '2',
    twitter: '7',
    linkedIn: '8',
    youtube: '6',
    snapchatShow: '9'
};

export const selectUseCaseById = createSelector(
    [
        getUseCasesFromStore,
        (_, id) => id
    ],
    (useCases, id) => useCases[id] || false
);

export const makeSelectUseCasesById = () => createSelector(
    [
        getUseCasesFromStore,
        (_, useCaseId) => useCaseId
    ],
    (useCases, useCaseId) => useCases[useCaseId] || []
);

export const makeSelectUseCasesByIds = () => createSelector(
    [
        getUseCasesFromStore,
        (_, useCaseIds) => useCaseIds
    ],
    (useCases, useCaseIds) => getFoundedEntities(useCaseIds, useCases)
);

export const selectUseCases = createSelector(
    [
        getUseCasesIdsFromStore,
        getUseCasesFromStore
    ],
    (useCaseIds, useCases) => useCaseIds.map((id) => useCases[id])
);

export const selectUseCasesByPlatform = createSelector(
    [
        getUseCasesFromStore,
        (_, platformType) => platformType
    ],
    (useCases, platformType) => Object.values(useCases).filter((useCase) => useCase.platformType === platformType)
);

export const selectUseCasesByPlatformWithBasicInsights = createSelector(
    [
        selectUseCasesByPlatform,
        (_, platformType) => platformType,
        (_, __, onlyBasicInsights) => onlyBasicInsights
    ],
    (useCases, platformType, onlyBasicInsights) => {
        if (onlyBasicInsights && basicUseCaseIdsByProfileType[platformType]) {
            const basicUseCaseId = basicUseCaseIdsByProfileType[platformType];
            return useCases.filter((useCase) => useCase.id === basicUseCaseId);
        }
        return useCases;
    }
);

export const getListNamePerUseCaseId = createSelector(
    [
        selectUseCases
    ],
    (useCases) => {
        let listNameByUseCaseIds = {};
        useCases.forEach((useCase) => {
            listNameByUseCaseIds = Object.assign(listNameByUseCaseIds, { [useCase.id]: `${useCase.platformType}_${useCase.id}` });
        });
        return listNameByUseCaseIds;
    }
);

export const makeSelectAllPlatformUseCases = () => createSelector(
    [
        getUseCasesFromStore
    ],
    (allUseCases) => {
        const allUseCasesArray = Object.values(allUseCases);
        return {
            facebook: allUseCasesArray.filter((useCase) => useCase.platformType === 'facebook'),
            instagram: allUseCasesArray.filter((useCase) => useCase.platformType === 'instagram'),
            youtube: allUseCasesArray.filter((useCase) => useCase.platformType === 'youtube'),
            twitter: allUseCasesArray.filter((useCase) => useCase.platformType === 'twitter'),
            linkedIn: allUseCasesArray.filter((useCase) => useCase.platformType === 'linkedIn'),
            snapchatShow: allUseCasesArray.filter((useCase) => useCase.platformType === 'snapchatShow'),
            tiktok: allUseCasesArray.filter((useCase) => useCase.platformType === 'tiktok'),
            meta: allUseCasesArray.filter((useCase) => useCase.platformType === 'meta'),
            threads: allUseCasesArray.filter((useCase) => useCase.platformType === 'threads'),
        };
    }
);
