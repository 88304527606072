import _get from 'lodash/get';
import _has from 'lodash/has';
import moment from 'moment';
import numeral from 'numeral';

export function getAxisType(dimension, data) {
    return _get(data, ['axes', dimension, 'type'], 'category');
}

export function formatNumber(number, format) {
    return numeral(number).format(format);
}

const getLargeNumbersShortDisplay = (num) => {
    let suffix = null;
    let ret;
    const numericSymbols = ['k', 'M', 'G', 'T', 'P', 'E'];
    let i = numericSymbols.length;
    if (num >= 1000) {
        while (i && ret === undefined) {
            i -= 1;
            const multi = 1000 ** (i + 1);
            if (num >= multi && numericSymbols[i] !== null) {
                ret = (num / multi);
                suffix = numericSymbols[i];
            }
        }
    }
    if (ret) {
        return { value: ret, suffix };
    }
    return { value: num, suffix: '' };
};

const getDecimalPlaces = (n) => {
    const s = `${+n}`;
    const match = /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/.exec(s);
    if (!match) {
        return 0;
    }
    return Math.max(0, (match[1] === '0' ? 0 : (match[1] || '').length) - (match[2] || 0));
};

const isFloat = (n) => (Number(n) === n && n % 1 !== 0);

export const makeFormatNumber = (Highcharts) => ({ decimalSeparator, thousandSeparator }, nb, initialPrecision = null, shortenNumber = false, decimal = 4) => {
    let decimalPlaces = getDecimalPlaces(nb);
    const maxDecimalPlaces = decimal;
    let precision = initialPrecision;
    if (!isFloat(nb)) {
        precision = 0;
    } else if (decimalPlaces > 0 && precision === null) {
        precision = Math.min(decimalPlaces, maxDecimalPlaces);
    }
    precision = isFloat(nb) ? precision : 0;
    if (shortenNumber) {
        const shortened = getLargeNumbersShortDisplay(nb);
        decimalPlaces = getDecimalPlaces(shortened.value);
        precision = Math.min(decimalPlaces, maxDecimalPlaces);
        return Highcharts.numberFormat(shortened.value, precision, decimalSeparator, thousandSeparator) + shortened.suffix;
    }
    return Highcharts.numberFormat(nb, precision, decimalSeparator, thousandSeparator);
};

function getWeekNumberFormatKey(weekDefinition) {
    const safeWeekDefinition = weekDefinition || 'MoSu';
    return safeWeekDefinition === 'SuSa' ? '%U' : '%w';
}

export function getStartOfWeek(weekDefinition) {
    const safeWeekDefinition = weekDefinition || 'MoSu';
    return safeWeekDefinition === 'SuSa' ? 0 : 1;
}

function getDayFormat(format, useCase) {
    let str = '';
    switch (format) {
        case 'MM/DD/YYYY':
            switch (useCase) {
                case 'highcharts':
                    str = '%m/%d';
                    break;
                case 'highChartXRange':
                    str = '%m/%d %H:%M';
                    break;
                case 'momentjs':
                    str = 'MM/DD/YYYY';
                    break;
                default:
                    break;
            }
            break;

        case 'DD.MM.YYYY':
            switch (useCase) {
                case 'highcharts':
                    str = '%d.%m.';
                    break;
                case 'highChartXRange':
                    str = '%d.%m %H:%M';
                    break;
                case 'momentjs':
                    str = 'DD.MM.YYYY';
                    break;
                default:
                    break;
            }
            break;

        case 'DD/MM/YYYY':
            switch (useCase) {
                case 'highcharts':
                    str = '%d/%m';
                    break;
                case 'highChartXRange':
                    str = '%d/%m %H:%M';
                    break;
                case 'momentjs':
                    str = 'DD/MM/YYYY';
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }

    return str;
}

export function getDateOptionsByIntervalType(intervalType, dateFormat, weekDefinition, tooltipUseCase = 'highcharts') {
    const result = {};

    // used static value here. this needs to be dynamic. sth like options.dateFormat that is passed down from the parent
    switch (intervalType) {
        case 'daily':
            result.dateFormat = {
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: getDayFormat(dateFormat, 'highcharts'),
                week: getDayFormat(dateFormat, 'highcharts'),
                month: '%b %y',
                year: '%Y'
            };
            result.dateFormatToolTip = getDayFormat(dateFormat, tooltipUseCase);
            result.minTickInterval = 3600 * 24 * 1000;
            break;

        case 'weekly':
            result.dateFormat = {
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: `${getDayFormat(dateFormat, 'highcharts')} (W${getWeekNumberFormatKey(weekDefinition)})`,
                week: `${getDayFormat(dateFormat, 'highcharts')} (W${getWeekNumberFormatKey(weekDefinition)})`,
                month: '%b %y',
                year: '%Y'
            };
            result.dateFormatToolTip = `${getDayFormat(dateFormat, tooltipUseCase)} (W${getWeekNumberFormatKey(weekDefinition)})`;
            result.minTickInterval = 3600 * 24 * 1000 * 7;
            break;

        case 'monthly':
            result.dateFormat = {
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: getDayFormat(dateFormat, 'highcharts'),
                week: '%b %y',
                month: '%b %Y',
                year: '%Y'
            };
            result.dateFormatToolTip = '%b %Y';
            result.minTickInterval = 3600 * 24 * 1000 * 30;
            break;

        case 'yearly':
            result.dateFormat = {
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: getDayFormat(dateFormat, 'highcharts'),
                week: '%b %y',
                month: '%b %y',
                year: '%Y'
            };
            result.dateFormatToolTip = '%Y';
            result.minTickInterval = 3600 * 24 * 1000 * 365;
            break;

        default:
            break;
    }
    return result;
}

export function getIntervalType(selectedDate, parameters) {
    if (_has(parameters, 'minTickInterval')) {
        return parameters.minTickInterval;
    }
    if (_has(parameters, 'interval')) {
        return parameters.interval;
    }
    if (_has(parameters, 'aggregationInterval')) {
        return parameters.aggregationInterval;
    }
    return selectedDate.interval;
}

export function formatDate(date, format) {
    return moment(date).format(getDayFormat(format, 'momentjs'));
}
