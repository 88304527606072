import { createSelector } from 'reselect';
import { getAccountOptions, selectAccountOptions } from 'src/selectors/loggedInUser';
import {
    additionalFilterToParams,
    isAdCampaignSelected,
    isPostTagSelected,
    isPostTextExcludeSelected,
    isPostTextSelected,
    profileSelectionToParams
} from 'src/utils/filterSelectors';
import { metricBuilderRoute } from 'src/routePaths';
import { createShallowEqualSelector } from 'src/selectors/utils';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { getDateConfigByString } from 'src/components/dateSelector/utils';
import moment from 'moment';
import _pick from 'lodash/pick';
import { getProfileIdsFromStore } from 'src/selectors/profiles';
import { getGroupIdsFromStore } from 'src/selectors/groups';
import _intersection from 'lodash/intersection';
import { getAdAccountIdsFromStore } from 'src/selectors/adAccounts';

export const getDefaultProfileSelector = (state) => state.filterSelectors.defaultSelectors.profile;
export const getDefaultDateSelector = (state) => state.filterSelectors.defaultSelectors.date;
export const getDefaultPostTextSelector = (state) => state.filterSelectors.defaultSelectors.postText;
export const getDefaultPostTextExcludeSelector = (state) => state.filterSelectors.defaultSelectors.postTextExclude;
export const getDefaultPostTagSelector = (state) => state.filterSelectors.defaultSelectors.postTag;
export const getDefaultAdCampaignSelector = (state) => state.filterSelectors.defaultSelectors.adCampaign;
export const getProfileSelector = (state) => state.filterSelectors.contextAwareFilterSelectors.profile;
export const getDateSelector = (state) => state.filterSelectors.contextAwareFilterSelectors.date;
export const getPostTextSelector = (state) => state.filterSelectors.contextAwareFilterSelectors.postText;
export const getPostTextExcludeSelector = (state) => state.filterSelectors.contextAwareFilterSelectors.postTextExclude;
export const getPostTagSelector = (state) => state.filterSelectors.contextAwareFilterSelectors.postTag;
export const getAdCampaignSelector = (state) => state.filterSelectors.contextAwareFilterSelectors.adCampaign;

const selectDefaultDateSelector = createSelector(
    [
        (state) => { const { timezone } = getAccountOptions(state); return timezone; },
        getDefaultDateSelector
    ],
    (defaultTimezone, dateSelection) => Object.assign({}, dateSelection, { timezone: defaultTimezone })
);

export const makeSelectProfileByContext = () => createSelector(
    [
        (_, context) => context,
        getProfileSelector,
        getDefaultProfileSelector
    ],
    (context, selectedProfile, defaultProfile) => {
        if (selectedProfile[context]) {
            return selectedProfile[context];
        }
        return defaultProfile;
    }
);

// Only allows valid selections, ensure by apply an intersection with current profile and group ids
export const makeFilterProfilesAndGroupsAvailable = () => createSelector(
    [
        (_, selectedProfilesOrGroups) => selectedProfilesOrGroups,
        getGroupIdsFromStore,
        getProfileIdsFromStore,
        getAdAccountIdsFromStore
    ],
    (selected, groupIds, profileIds, adAccountIds) => {
        const selection = {
            profileIds: _intersection(_get(selected, 'profileIds', []), profileIds),
            groupIds: _intersection(_get(selected, 'groupIds', []), groupIds),
            adAccountIds: _intersection(_get(selected, 'adAccountIds', []), adAccountIds)
        };
        return selection;
    }
);

export const makeSelectProfileSelectorSelectionByContext = () => {
    const selectProfileByContext = makeSelectProfileByContext();
    const filterProfilesByAvailableGroups = makeFilterProfilesAndGroupsAvailable();
    return (state, context) => (filterProfilesByAvailableGroups(state, selectProfileByContext(state, context)));
};

export const makeSelectDateByContext = () => createSelector(
    [
        (_, context) => context,
        getDateSelector,
        selectDefaultDateSelector,
        (state) => {
            const { timezone } = selectAccountOptions(state);
            return timezone;
        }
    ],
    (context, selectedDate, defaultDate, defaultTimezone) => {
        let date = defaultDate;
        if (selectedDate[context] && !_isEmpty(selectedDate[context])) {
            date = selectedDate[context];
        }
        if (!_has(date, 'timezone')) {
            date = Object.assign({}, date, { timezone: defaultTimezone });
        }
        return date;
    }
);

export const makeSelectPostTextByContext = () => createSelector(
    [
        (_, context) => context,
        getPostTextSelector,
        getDefaultPostTextSelector
    ],
    (context, selectedPostText, defaultPostText) => {
        if (selectedPostText[context]) {
            return selectedPostText[context];
        }
        return defaultPostText;
    }
);

export const makeAdCampaignByContext = () => createSelector(
    [
        (_, context) => context,
        getAdCampaignSelector,
        getDefaultAdCampaignSelector
    ],
    (context, selectedAdCampaign, defaultAdCampaign) => _get(selectedAdCampaign, context, defaultAdCampaign)
);

export const makeSelectPostTextExcludeByContext = () => createSelector(
    [
        (_, context) => context,
        getPostTextExcludeSelector,
        getDefaultPostTextExcludeSelector
    ],
    (context, selectedPostText, defaultPostText) => {
        if (selectedPostText[context]) {
            return selectedPostText[context];
        }
        return defaultPostText;
    }
);

export const makeSelectPostTagByContext = () => createSelector(
    [
        (_, context) => context,
        getPostTagSelector,
        getDefaultPostTagSelector
    ],
    (context, selectedPostTag, defaultPostTag) => {
        if (selectedPostTag[context]) {
            return selectedPostTag[context];
        }
        return defaultPostTag;
    }
);

export const makeAdditionalFilterSelectorsByContext = () => ({
    selectPostTextByContext: makeSelectPostTextByContext(),
    selectPostTextExcludeByContext: makeSelectPostTextExcludeByContext(),
    selectPostTagByContext: makeSelectPostTagByContext(),
    selectAdCampaignByContext: makeAdCampaignByContext()
});

const deriveAdditionalFilter = (postText, postTextExclude, postTag, adCampaign) => {
    const appliedFilters = {};
    if (isPostTextSelected(postText)) {
        Object.assign(appliedFilters, { postText });
    }
    if (isPostTextExcludeSelected(postTextExclude)) {
        Object.assign(appliedFilters, { postTextExclude });
    }
    if (isPostTagSelected(postTag)) {
        Object.assign(appliedFilters, { postTag });
    }

    if (isAdCampaignSelected(adCampaign)) {
        Object.assign(appliedFilters, { adCampaign });
    }

    return appliedFilters;
};

export const makeSelectAdditionalFilterByContext = () => {
    const {
        selectPostTagByContext, selectPostTextByContext, selectPostTextExcludeByContext, selectAdCampaignByContext
    } = makeAdditionalFilterSelectorsByContext();
    return createSelector(
        [
            selectPostTextByContext,
            selectPostTextExcludeByContext,
            selectPostTagByContext,
            selectAdCampaignByContext
        ],
        (postText, postTextExclude, postTag, adCampaign) => deriveAdditionalFilter(postText, postTextExclude, postTag, adCampaign)
    );
};

export const selectDefaultAdditionalFilter = createSelector(
    [
        getDefaultPostTextSelector,
        getDefaultPostTextExcludeSelector,
        getDefaultPostTagSelector,
        getDefaultAdCampaignSelector
    ],
    (postText, postTextExclude, postTag, adCampaign) => deriveAdditionalFilter(postText, postTextExclude, postTag, adCampaign)
);

export const makeSelectQueryFromFilterSelectorByContext = () => {
    const selectDateByContext = makeSelectDateByContext();
    const selectAdditionalFilterByContext = makeSelectAdditionalFilterByContext();
    const selectProfileSelectorSelectionByContext = makeSelectProfileSelectorSelectionByContext();

    return createSelector(
        [
            selectDateByContext,
            selectProfileSelectorSelectionByContext,
            selectAdditionalFilterByContext
        ],
        (date, profileSelectorSelection, additionalFilter) => {
            const queryObject = Object.assign({}, date);
            if (profileSelectorSelection) {
                queryObject.profile = profileSelectionToParams(profileSelectorSelection);
            }
            const additionalFilterParams = additionalFilterToParams(additionalFilter);
            Object.assign(queryObject, additionalFilterParams);
            return queryObject;
        }
    );
};

export const selectDefaultQueryParameters = createSelector(
    [
        selectDefaultDateSelector,
        getDefaultProfileSelector,
        selectDefaultAdditionalFilter
    ],
    (date, profileSelectorSelection, additionalFilter) => {
        const queryObject = {
            from: date.from,
            to: date.to,
            interval: date.interval
        };
        if (profileSelectorSelection) {
            queryObject.profile = profileSelectorSelection.string;
        }
        const additionalFilterParams = additionalFilterToParams(additionalFilter);
        Object.assign(queryObject, additionalFilterParams);
        return queryObject;
    }
);

export const makeSelectDashboardLink = () => {
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    return createSelector(
        [
            selectQueryFromFilterSelectorByContext,
            (_, __, dashboardId) => dashboardId
        ],
        (query, dashboardId) => Object.assign({}, { pathname: `/dashboard/${dashboardId}` }, { query })
    );
};

export const makeSelectEditMetricLink = () => {
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    return createSelector(
        [
            selectQueryFromFilterSelectorByContext,
            (_, __, metricId) => metricId,
        ],
        (query, metricId) => Object.assign({}, { pathname: `${metricBuilderRoute}/${metricId}` }, { query })
    );
};

export const makeSelectMetricBuilderLink = () => {
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    return createSelector(
        [
            selectQueryFromFilterSelectorByContext
        ],
        (query) => Object.assign({}, { pathname: `${metricBuilderRoute}` }, { query })
    );
};

export const makeSelectDashboardMetricLink = () => {
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    return createSelector(
        [
            selectQueryFromFilterSelectorByContext,
            (_, __, dashboardId) => dashboardId,
            (_, __, ___, dashboardMetricId) => dashboardMetricId
        ],
        (query, dashboardId, dashboardMetricId) => Object.assign({}, { pathname: `/dashboard/${dashboardId}/metric/${dashboardMetricId}` }, { query })
    );
};

export const makeSelectMetricPreviewLink = () => {
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    return createSelector(
        [
            selectQueryFromFilterSelectorByContext,
            (_, __, metricId) => metricId,
        ],
        (query, metricId) => Object.assign({}, { pathname: `/discover/metric/${metricId}/preview` }, { query })
    );
};

export const makeSelectMetricDetailLink = () => createSelector(
    [
        (_, metricId) => metricId
    ],
    (metricId) => ({ pathname: `/discover/metric/${metricId}` })
);

export const makeSelectLinkForDashboardMetrics = () => createShallowEqualSelector(
    [
        (state, dashboardMetric) => {
            const { settings } = dashboardMetric;
            if (_has(settings, 'isCustomProfileSelected') && settings.isCustomProfileSelected === true && settings.settingProfileSelection) {
                return settings.settingProfileSelection;
            }
            return false;
        },
        (state, dashboardMetric) => {
            const { settings } = dashboardMetric;
            if (_has(settings, 'isCustomDateSelected') && settings.isCustomDateSelected === true && !_isEmpty(settings.settingDateSelection)) {
                const selectedDate = settings.settingDateSelection;
                const { inheritTimezone } = settings;
                if (_get(selectedDate, 'dynamicDate', false)) {
                    const date = getDateConfigByString(selectedDate.dynamicDate);
                    if (_has(date, 'from') && _has(date, 'to') && _has(selectedDate, 'interval')) {
                        const overwrittenSelectedDate = {
                            interval: selectedDate.interval,
                            from: moment(date.from).format('YYYY-MM-DD'),
                            to: moment(date.to).format('YYYY-MM-DD')
                        };
                        if (inheritTimezone !== true) {
                            Object.assign(overwrittenSelectedDate, { timezone: selectedDate.timezone });
                        }
                        return overwrittenSelectedDate;
                    }
                    return false;
                }

                if (_has(selectedDate, 'to') && _has(selectedDate, 'from') && _has(selectedDate, 'interval') && _has(selectedDate, 'timezone')) {
                    const pick = ['from', 'to', 'interval'];
                    if (inheritTimezone !== true) {
                        pick.push(['timezone']);
                    }
                    return _pick(selectedDate, pick);
                }
            }
            return false;
        },
        (_, __, link) => link
    ],
    (customSelectedGroupsOrProfiles, customDate, link) => {
        if (!!customSelectedGroupsOrProfiles || !!customDate) {
            const newQuery = Object.assign({}, link.query);
            if (customSelectedGroupsOrProfiles) {
                const selectedParams = profileSelectionToParams(customSelectedGroupsOrProfiles);
                Object.assign(newQuery, { profile: selectedParams });
            }
            if (customDate) {
                Object.assign(newQuery, customDate);
            }
            return Object.assign({}, link, { query: newQuery });
        }
        return link;
    }
);
