import { createFormAction } from 'redux-form-saga';

export const SORT_POST_TAG_RULES = 'SORT_POST_TAG_RULES';

export const POST_TAG_RULES_GET_REQUEST = 'POST_TAG_RULES_GET_REQUEST';
export const POST_TAG_RULES_GET_SUCCESS = 'POST_TAG_RULES_GET_SUCCESS';
export const POST_TAG_RULES_GET_ERROR = 'POST_TAG_RULES_GET_ERROR';

export const POST_TAG_RULE_ADD_REQUEST = 'POST_TAG_RULE_ADD_REQUEST';
export const POST_TAG_RULE_ADD_SUCCESS = 'POST_TAG_RULE_ADD_SUCCESS';
export const POST_TAG_RULE_ADD_ERROR = 'POST_TAG_RULE_ADD_ERROR';

export const POST_TAG_RULE_UPDATE_REQUEST = 'POST_TAG_RULE_UPDATE_REQUEST';
export const POST_TAG_RULE_UPDATE_SUCCESS = 'POST_TAG_RULE_UPDATE_SUCCESS';
export const POST_TAG_RULE_UPDATE_ERROR = 'POST_TAG_RULE_UPDATE_ERROR';

export const POST_TAG_RULE_DELETE_REQUEST = 'POST_TAG_RULE_DELETE_REQUEST';
export const POST_TAG_RULE_DELETE_SUCCESS = 'POST_TAG_RULE_DELETE_SUCCESS';
export const POST_TAG_RULE_DELETE_ERROR = 'POST_TAG_RULE_DELETE_ERROR';

export const sortPostTagRules = (sortBy) => ({
    type: SORT_POST_TAG_RULES,
    payload: { sortBy }
});

export const postTagRulesGetRequest = () => ({
    type: POST_TAG_RULES_GET_REQUEST
});

export const postTagRulesGetSuccess = (postTagRules) => ({
    type: POST_TAG_RULES_GET_SUCCESS,
    payload: { postTagRules }
});

export const postTagRulesGetError = (error) => ({
    type: POST_TAG_RULES_GET_ERROR,
    payload: { error }
});

export const postTagRuleAddRequest = ({
    name, from, to, profileSelection, postTextValues, postTextConjunction, postTagId, automationEnabled, timezone, postTextExcludeValues, postTextExcludeConjunction
}) => ({
    type: POST_TAG_RULE_ADD_REQUEST,
    payload: {
        name, from, to, profileSelection, postTextValues, postTextConjunction, postTagId, automationEnabled, timezone, postTextExcludeValues, postTextExcludeConjunction
    }
});

export const postTagRuleAddSuccess = (postTagRule = null) => ({
    type: POST_TAG_RULE_ADD_SUCCESS,
    payload: { postTagRule }
});

export const postTagRuleAddError = (error) => ({
    type: POST_TAG_RULE_ADD_ERROR,
    payload: error
});

export const postTagRuleAddRequestFormAction = createFormAction(postTagRuleAddRequest, [POST_TAG_RULE_ADD_SUCCESS, POST_TAG_RULE_ADD_ERROR]);

export const postTagRuleUpdateRequest = ({
    postTagRuleId, name, from, to, profileSelection, postTextValues, postTextConjunction, postTextExcludeValues, postTextExcludeConjunction, postTagId, isActive, timezone
}) => ({
    type: POST_TAG_RULE_UPDATE_REQUEST,
    payload: {
        postTagRuleId, name, from, to, profileSelection, postTextValues, postTextConjunction, postTagId, isActive, timezone, postTextExcludeValues, postTextExcludeConjunction
    }
});

export const postTagRuleUpdateSuccess = (postTagRule = null) => ({
    type: POST_TAG_RULE_UPDATE_SUCCESS,
    payload: { postTagRule }
});

export const postTagRuleUpdateError = (error) => ({
    type: POST_TAG_RULE_UPDATE_ERROR,
    payload: error
});

export const postTagRuleUpdateRequestFormAction = createFormAction(
    postTagRuleUpdateRequest, [POST_TAG_RULE_UPDATE_SUCCESS, POST_TAG_RULE_UPDATE_ERROR]
);

export const postTagRuleDeleteRequest = ({ postTagId, postTagRuleId, removeTagsOnPosts }) => ({
    type: POST_TAG_RULE_DELETE_REQUEST,
    payload: { postTagId, postTagRuleId, removeTagsOnPosts }
});

export const postTagRulesDeleteSuccess = (postTagId, postTagRuleId) => ({
    type: POST_TAG_RULE_DELETE_SUCCESS,
    payload: { postTagId, postTagRuleId }
});

export const postTagRulesDeleteError = (error) => ({
    type: POST_TAG_RULE_DELETE_ERROR,
    payload: error
});

export const postTagRuleDeleteRequestFormRequest = createFormAction(
    postTagRuleDeleteRequest, [POST_TAG_RULE_DELETE_SUCCESS, POST_TAG_RULE_DELETE_ERROR]
);
