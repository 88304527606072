import {
    authUserRemoveRequest,
    authUserRemoveError,
    authUserRemoveSuccess
} from 'src/actions/authUsers';
import { SubmissionError } from 'redux-form';

import { put, takeEvery, call } from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import {
    handleAuthorizedServerRequest,
    notAbleToLoadSDKmessage,
    getScopesByUseCases,
    createFacebookLoginRequest,
    handleApiRequests, popUpWindowWithCallbackUrlAction
} from 'src/sagas/utils';
import { modalsHideAuthUserDelete } from 'src/actions/overlays';
import { showNotification } from 'src/actions/notifications';
import _has from 'lodash/has';
import {
    getLinkedInAuthenticationCallbackUrl,
    getTwitterAuthenticationCallbackUrl,
    getYoutubeAuthenticationCallbackUrl,
    getSnapchatAuthenticationCallbackUrl,
    getTiktokAuthenticationCallbackUrl
} from 'src/utils/authUsers';

function* authUserRemoveRequestAction(action) {
    const { id, environment } = action.payload;
    try {
        const serverRequest = createServerRequest({ id, environment });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/delete-auth-user');
        if (response) {
            yield put(authUserRemoveSuccess(id));
            yield put(modalsHideAuthUserDelete());
            yield put(showNotification('User successfully removed.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(authUserRemoveError(new SubmissionError({ _error: applicationError })));
    }
}

export function* graphAuthUserAuthenticationRequest(useCaseIds) {
    if (!_has(window, 'FB')) {
        return {
            serverError: new Error(notAbleToLoadSDKmessage)
        };
    }
    try {
        let finalScopes = yield call(getScopesByUseCases, useCaseIds, 'graph');
        // Add optional permission scope to fix FB bug: https://developers.facebook.com/support/bugs/602264498523167/?join_id=f35345fa91df558
        finalScopes = `${finalScopes},business_management`;
        const fbLoginRequest = createFacebookLoginRequest(finalScopes);
        const fbLoginResponse = yield call(handleApiRequests, fbLoginRequest);
        const { response, serverError } = fbLoginResponse;
        return {
            response,
            serverError: serverError ? new Error('Login to Facebook was unsuccessful. Please try again.') : undefined
        };
    } catch (applicationError) {
        return {
            serverError: applicationError
        };
    }
}

export function* linkedInAuthUserAuthenticationRequest(useCaseIds) {
    const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'linkedIn');
    const url = getLinkedInAuthenticationCallbackUrl(finalScopes);
    try {
        return yield call(popUpWindowWithCallbackUrlAction, url, 'LinkedIn');
    } catch (applicationError) {
        return {
            serverError: applicationError
        };
    }
}

export function* twitterAuthUserAuthenticationRequest(requestId, screenName = null) {
    const url = getTwitterAuthenticationCallbackUrl(requestId, screenName);
    try {
        return yield call(popUpWindowWithCallbackUrlAction, url, 'X');
    } catch (applicationError) {
        return {
            serverError: applicationError
        };
    }
}

export function* youtubeAuthUserAuthenticationRequest(useCaseIds) {
    const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'youtube');
    const url = getYoutubeAuthenticationCallbackUrl(finalScopes);
    try {
        return yield call(popUpWindowWithCallbackUrlAction, url, 'Youtube');
    } catch (applicationError) {
        return {
            serverError: applicationError
        };
    }
}

export function* snapchatAuthUserAuthenticationRequest(useCaseIds) {
    const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'snapchat');
    const url = getSnapchatAuthenticationCallbackUrl(finalScopes);
    try {
        const { response, serverError } = yield call(popUpWindowWithCallbackUrlAction, url, 'Snapchat show');
        return {
            response,
            serverError
        };
    } catch (applicationError) {
        return {
            serverError: applicationError
        };
    }
}

export function* tiktokAuthUserAuthenticationRequest(useCaseIds) {
    const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'tiktok');
    const url = getTiktokAuthenticationCallbackUrl(finalScopes);
    try {
        return yield call(popUpWindowWithCallbackUrlAction, url, 'Tiktok');
    } catch (applicationError) {
        return {
            serverError: applicationError
        };
    }
}

export default function* authUsersSaga() {
    yield takeEvery(authUserRemoveRequest.type, authUserRemoveRequestAction);
}
