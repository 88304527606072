export const SEARCH_POSTS_REQUEST = 'SEARCH_POSTS_REQUEST';
export const SEARCH_POSTS_SUCCESS = 'SEARCH_POSTS_SUCCESS';
export const SEARCH_POSTS_ERROR = 'SEARCH_POSTS_ERROR';

export const PAGINATE_POSTS_REQUEST = 'PAGINATE_POSTS_REQUEST';
export const PAGINATE_POSTS_SUCCESS = 'PAGINATE_POSTS_SUCCESS';
export const PAGINATE_POSTS_ERROR = 'PAGINATE_POSTS_ERROR';

export const GET_POST_REQUEST = 'GET_POST_REQUEST';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_ERROR = 'GET_POST_ERROR';

export const SHARED_DASHBOARD_POST_GET_REQUEST = 'SHARED_DASHBOARD_POST_GET_REQUEST';
export const SHARED_DASHBOARD_POST_GET_SUCCESS = 'GET_SHARED_DASHBOARD_POST_GET_SUCCESS';
export const SHARED_DASHBOARD_POST_GET_ERROR = 'GET_SHARED_DASHBOARD_POST_GET_ERROR';

export const ADD_REMOVE_POST_TAGS_FROM_POSTS_REQUEST = 'ADD_REMOVE_POST_TAGS_FROM_POSTS_REQUEST';
export const ADD_REMOVE_POST_TAGS_FROM_POSTS_SUCCESS = 'ADD_REMOVE_POST_TAGS_FROM_POSTS_SUCCESS';
export const ADD_REMOVE_POST_TAGS_FROM_POSTS_ERROR = 'ADD_REMOVE_POST_TAGS_FROM_POSTS_ERROR';

export const ADD_REMOVE_POST_TAGS_FROM_POST_REQUEST = 'ADD_REMOVE_POST_TAGS_FROM_POST_REQUEST';
export const ADD_REMOVE_POST_TAGS_FROM_POST_SUCCESS = 'ADD_REMOVE_POST_TAGS_FROM_POST_SUCCESS';
export const ADD_REMOVE_POST_TAGS_FROM_POST_ERROR = 'ADD_REMOVE_POST_TAGS_FROM_POST_ERROR';
export const CLEANUP_ADD_REMOVE_POST_TAGS_FROM_POST_STATE = 'CLEANUP_ADD_REMOVE_POST_TAGS_FROM_POST_STATE';

export const ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_REQUEST = 'ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_REQUEST';
export const ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_SUCCESS = 'ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_SUCCESS';
export const ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_ERROR = 'ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_ERROR';

export const TAG_ALL_POSTS = 'TAG_ALL_POSTS';
export const CHANGE_POST_SORT_DIR = 'CHANGE_POST_SORT_DIR';

export const INVALIDATE_POST_SEARCH_HASH = 'INVALIDATE_POST_SEARCH_HASH';

export const searchPostsRequest = (searchRequestInput) => ({
    type: SEARCH_POSTS_REQUEST,
    payload: {
        searchRequestInput
    }
});

export const searchPostsSuccess = (posts, postPostTags, totalRowCount, pageNumber, warnings) => ({
    type: SEARCH_POSTS_SUCCESS,
    payload: {
        posts, postPostTags, totalRowCount, pageNumber, warnings
    }
});

export const searchPostsError = (error) => ({
    type: SEARCH_POSTS_ERROR,
    payload: { error }
});

export const tagAllPosts = () => ({
    type: TAG_ALL_POSTS
});

export const addRemovePostTagsFromPostRequest = (postId, selectedTagOptions, performPostSearch) => ({
    type: ADD_REMOVE_POST_TAGS_FROM_POST_REQUEST,
    payload: { postId, selectedTagOptions, performPostSearch }
});

export const addRemovePostTagsFromPostSuccess = (addToPostTags, removeFromPostTags) => ({
    type: ADD_REMOVE_POST_TAGS_FROM_POST_SUCCESS,
    payload: { addToPostTags, removeFromPostTags }
});

export const addRemovePostTagsFromPostError = (error) => ({
    type: ADD_REMOVE_POST_TAGS_FROM_POST_ERROR,
    payload: { error }
});

export const addRemovePostTagsFromPostsRequest = (postIds, selectedTagOptions) => ({
    type: ADD_REMOVE_POST_TAGS_FROM_POSTS_REQUEST,
    payload: { postIds, selectedTagOptions }
});

export const addRemovePostTagsFromPostsSuccess = (addToPostTags, removeFromPostTags) => ({
    type: ADD_REMOVE_POST_TAGS_FROM_POSTS_SUCCESS,
    payload: { addToPostTags, removeFromPostTags }
});

export const addRemovePostTagsFromPostsError = (error) => ({
    type: ADD_REMOVE_POST_TAGS_FROM_POSTS_ERROR,
    payload: { error }
});

export const addRemovePostTagsFromPostByFilterRequest = (postIds, selectedTagOptions) => ({
    type: ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_REQUEST,
    payload: { postIds, selectedTagOptions }
});

export const addRemovePostTagsFromPostByFilterSuccess = (addToPostTags, removeFromPostTags) => ({
    type: ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_SUCCESS,
    payload: { addToPostTags, removeFromPostTags }
});

export const addRemovePostTagsFromPostByFilterError = (error) => ({
    type: ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_ERROR,
    payload: error
});

export const changePostSortDir = (sortDir) => ({
    type: CHANGE_POST_SORT_DIR,
    payload: { sortDir }
});

export const getPostRequest = (postId) => ({
    type: GET_POST_REQUEST,
    payload: { postId }
});

export const getPostSuccess = (postId, post, postPostTags) => ({
    type: GET_POST_SUCCESS,
    payload: { post, postId, postPostTags }
});

export const getPostError = (postId, error) => ({
    type: GET_POST_ERROR,
    payload: { postId, error }
});

export const sharedDashboardPostGetRequest = (postId, metricRequest) => ({
    type: SHARED_DASHBOARD_POST_GET_REQUEST,
    payload: { postId, metricRequest }
});

export const sharedDashboardPostGetSuccess = (postId, post, postPostTags) => ({
    type: SHARED_DASHBOARD_POST_GET_SUCCESS,
    payload: { post, postId, postPostTags }
});

export const sharedDashboardPostGetError = (postId, error) => ({
    type: SHARED_DASHBOARD_POST_GET_ERROR,
    payload: { postId, error }
});

export const paginatePostsRequest = (pageNumber, searchRequestInput) => ({
    type: PAGINATE_POSTS_REQUEST,
    payload: {
        pageNumber, searchRequestInput
    }
});

export const paginatePostsSuccess = (posts, postPostTags, pageNumber) => ({
    type: PAGINATE_POSTS_SUCCESS,
    payload: { posts, postPostTags, pageNumber }
});

export const paginatePostsError = (error) => ({
    type: PAGINATE_POSTS_ERROR,
    payload: { error }
});

export const invalidatePostSearchHash = () => ({
    type: INVALIDATE_POST_SEARCH_HASH
});

export const cleanupAddRemovePostTagsFromPostsState = () => ({
    type: CLEANUP_ADD_REMOVE_POST_TAGS_FROM_POST_STATE
});
