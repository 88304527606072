export const SERVER_DATA_REQUEST = 'SERVER_DATA_REQUEST';
export const SERVER_DATA_SUCCESS = 'SERVER_DATA_SUCCESS';

export const SERVER_DATA_BUCKET_SUCCESS = 'SERVER_DATA_BUCKET_SUCCESS';
export const SERVER_DATA_BUCKET_ERROR = 'SERVER_DATA_BUCKET_ERROR';

export const SERVER_DATA_INVALIDATE_HASH_KEYS = 'SERVER_DATA_INVALIDATE_HASH_KEYS';

export const SERVER_DATA_CLEAR_CACHE = 'SERVER_DATA_CLEAR_CACHE';
export const SERVER_DATA_CLEAR_ALL_CACHE = 'SERVER_DATA_CLEAR_ALL_CACHE';

export const serverDataRequest = (batchIdentifier, serverDataJobs, endpointInfo, requestVariables = {}) => ({
    type: SERVER_DATA_REQUEST,
    payload: {
        batchIdentifier,
        serverDataJobs,
        endpointInfo,
        requestVariables
    }
});

export const severDataSuccess = (batchIdentifier) => ({
    type: SERVER_DATA_SUCCESS,
    payload: {
        batchIdentifier
    }
});

export const serverDataBucketSuccess = (collectedResponses) => ({
    type: SERVER_DATA_BUCKET_SUCCESS,
    payload: { collectedResponses }
});

export const serverDataBucketError = (collectedResponses) => ({
    type: SERVER_DATA_BUCKET_ERROR,
    payload: { collectedResponses }
});

export const serverDataInvalidateHashKeys = (keysToInvalidate) => ({
    type: SERVER_DATA_INVALIDATE_HASH_KEYS,
    payload: { keysToInvalidate }
});

export const serverDataClearForRequestIdentifier = (requestIdentifiers) => ({
    type: SERVER_DATA_CLEAR_CACHE,
    payload: {
        requestIdentifiers,
    }
});

export const serverDataClearAllCache = () => ({
    type: SERVER_DATA_CLEAR_ALL_CACHE,
});
